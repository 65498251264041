import React, {useEffect, useMemo, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, Link, useRouteMatch} from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import TopAppBar, {
    TopAppBarIcon,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle
} from "@material/react-top-app-bar";
import { Drawer, DrawerHeader, DrawerContent } from "@rmwc/drawer";
import { IconButton } from "@rmwc/icon-button";
import { Button } from "@rmwc/button";
import { Typography } from "@rmwc/typography";
import { fetchSignedContracts } from "../../../actions/contractActions";
import MenuItemMobile from "../../menu/MenuItemMobile";
import MenuNotification from "../../menu/MenuNotification";
import logoMobile from "../../../images/logo-white.svg";
import {
    mobileMenuHierarchy,
    mobileMenuHierarchyLv,
    mobileMenuHierarchyCompany
} from "../../../const/menu";
import {
    StartPath,
    ProductionPath,
    ConsumptionPath,
    DebugPath,
    HelpAndContactsPath,
    SettingsPath,
    TranslationSettingsPath,
    RolesPath
} from "../../../const/routes";
import LinkElement from "../../common/LinkElement";
import RoleDialog from "../../LoginForm/RoleDialog";
import LanguageSelector from "../../LanguageSelector";
import { Grid, GridCell } from "@rmwc/grid";
import auth from "../../../auth/authenticate";
import { IsLatvianWeb } from "../../../helpers/AlternativeDesign";
import { GasNew, ElectricityNew } from "../../../const/contractType";
import { useFeatures } from "flagged";

import "../../../styles/react-components/header.scss";
import "@rmwc/list/collapsible-list.css";
import {fetchRoles} from "../../../actions/accountActions";

const HeaderMobile = () => {
    const rolesSelection = useRef(useRouteMatch({path: RolesPath, exact: true}));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState();
    const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false);

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const { data: contracts, fetched: contractsFetched, fetching: contractsFetching } = useSelector(state => state.signedContracts);
    const { data: roles, fetched: rolesFetched, fetching: rolesFetching} = useSelector(state => state.roles);

    const history = useHistory();
    const dispatch = useDispatch();
    const features = useFeatures();

    const isLatvianWeb = IsLatvianWeb();
    const isAdmin = auth.isAdmin();

    useEffect(() => {
        const unregisterHistoryListener = history.listen(() => {
            setIsDrawerOpen(false);
        });

        const selectedItem = isLatvianWeb ? mobileMenuHierarchyLv[0] : mobileMenuHierarchy[0];
        setSelectedMenuItem(selectedItem);

        if (isLoggedIn && !contractsFetched && !contractsFetching) {
            dispatch(fetchSignedContracts());
        }

        if (isLoggedIn && rolesSelection.current && !rolesFetched && !rolesFetching) {
            dispatch(fetchRoles());
        }

        return unregisterHistoryListener;
    }, [dispatch, isLoggedIn, isLatvianWeb, contractsFetched, contractsFetching, rolesFetched, rolesFetching, history]);

    useEffect(() => {
        if (rolesSelection.current && rolesFetched && roles.length > 0) {
            setIsRolesDialogOpen(true);
        }
    }, [rolesFetched, roles]);

    const onCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const onOpenRolesDialog = () => {
        setIsDrawerOpen(false);
        setIsRolesDialogOpen(true);
    }

    const logout = () => {
        auth.startLogout();
    }

    const renderDrawerTitle = () => {
        return (
            <DrawerHeader>
                <span className="logo-wrapper">
                    {renderTitle()}
                </span>
                <div className="d-flex d-flex--inline align-right">
                    <IconButton
                        checked
                        onIcon={{
                            icon: "close",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-"
                        }}
                        icon={{}}
                        className="align-left mdc-icon-button--clear item close"
                        onClick={onCloseDrawer}
                    ></IconButton>
                </div>
            </DrawerHeader>
        );
    };

    const hierarchy = useMemo(() => {
        let menu = null;
        if (IsLatvianWeb()) {
            menu = mobileMenuHierarchyLv;
        } else if (auth.isCompany()) {
            menu = mobileMenuHierarchyCompany;
        } else {
            menu = mobileMenuHierarchy;
        }

        return menu.filter(item => !item.featureFlag || (!!item.featureFlag && features[item.featureFlag]));
    }, [features]);

    const renderDrawerContent = () => {
        const hasGasOrElectricityContract = contracts.find(c => c.type === GasNew || c.type === ElectricityNew) !== undefined;

        const roleName = auth.getRole();

        let hierarchyFiltered = hierarchy;
        if (!hasGasOrElectricityContract) {
            hierarchyFiltered = hierarchyFiltered.filter(item => item.path !== ProductionPath && item.path !== ConsumptionPath);
        }

        const mapped = hierarchyFiltered.map((navigation, i) => {
            return (
                <MenuItemMobile
                    setItem={setSelectedMenuItem}
                    selectedItem={selectedMenuItem}
                    item={navigation}
                    key={i}
                />
            );
        })

        return (
            <DrawerContent>
                <div
                    className={classnames("mdc-drawer-section", {
                        active: isDrawerOpen
                    })}
                >
                    <Grid className="mdc-layout-grid--full-height">
                        <GridCell span={12} align="top">
                            <div className="profile" >
                                <Typography use="body1" className="mdc-typography mdc-typography--body1--bold mdc-theme--on-primary">
                                    {roleName ? roleName.title : null}
                                </Typography>

                                {(!isLatvianWeb || isAdmin) &&
                                    <Button
                                        onClick={onOpenRolesDialog}
                                        className="mdc-button--text mdc-button--bold mdc-theme--secondary"
                                        icon={{
                                            icon: "angle-down",
                                            strategy: "className",
                                            basename: "icon",
                                            prefix: "icon-",
                                            style: {fontSize: "0.5rem"}
                                        }}>
                                        <FormattedMessage id="Header.RoleSelection"/>
                                    </Button>
                                }
                            </div>

                            <div className="mdc-drawer-section--navigation">
                                <LinkElement href={SettingsPath} className="mdc-list-submenu--no-children">
                                    <FormattedMessage id="Menu.Settings" />
                                </LinkElement>
                                {isAdmin &&
                                    <>
                                        <LinkElement href={TranslationSettingsPath} className="mdc-list-submenu--no-children">
                                            <FormattedMessage id="Menu.TranslationSettings" />
                                        </LinkElement>
                                        <LinkElement href={DebugPath} className="mdc-list-submenu--no-children">
                                            Debug
                                        </LinkElement>
                                    </>
                                }
                                {!isLatvianWeb &&
                                    <LinkElement href={HelpAndContactsPath} className="mdc-list-submenu--no-children">
                                        <FormattedMessage id="Menu.HelpAndContacts"/>
                                    </LinkElement>
                                }
                                <LinkElement onClick={logout} className="mdc-list-submenu--no-children">
                                    <Typography use="highlighted" className="mdc-typography">
                                        <FormattedMessage id="Header.LogOut" />
                                    </Typography>
                                </LinkElement>
                            </div>
                        </GridCell>
                        <GridCell span={12} align="bottom">
                            <div className="mdc-drawer-section--navigation">
                                {mapped}
                            </div>
                            {!isLatvianWeb && <LanguageSelector />}
                        </GridCell>
                    </Grid>
                </div>
            </DrawerContent >
        );
    };

    const renderTitle = () => {
        return (
            <Link to={StartPath} className="logo" tabIndex={0}>
                <img src={logoMobile} alt="Alexela logo" />
            </Link>
        );
    }

    return (
        <>
            <div className="mdc-drawer-scrim"></div>
            <Drawer
                modal
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}

                className="mdc-drawer--fluid mdc-drawer--reverse mdc-drawer--primary mdc-drawer--mobile-nav"
            >
                {renderDrawerTitle()}
                {renderDrawerContent()}
            </Drawer>

            <TopAppBar
                fixed="true"
                tag="div"
            >
                <TopAppBarRow>
                    <TopAppBarSection align="start">
                        <TopAppBarTitle >
                            {renderTitle()}
                        </TopAppBarTitle>
                    </TopAppBarSection>
                    <TopAppBarSection align="end" role="toolbar">
                        {isLoggedIn &&
                            <>
                                <TopAppBarIcon actionItem>
                                    <MenuNotification />
                                </TopAppBarIcon>
                                <TopAppBarIcon actionItem>
                                    <IconButton
                                        checked
                                        onIcon={{
                                            icon: "navicon",
                                            strategy: "className",
                                            basename: "icon2",
                                            prefix: "icon-"
                                        }}
                                        onClick={() => setIsDrawerOpen(true)}
                                    ></IconButton>
                                </TopAppBarIcon>
                            </>
                        }
                    </TopAppBarSection>
                </TopAppBarRow>
            </TopAppBar>

            <div className="drawer-padding"></div>
            {isLoggedIn &&
                <RoleDialog
                    isOpen={isRolesDialogOpen}
                    onClose={() => setIsRolesDialogOpen(false)}
                />
            }
        </>
    );
}

export default HeaderMobile;
