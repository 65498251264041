import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BaseFormComponent from "../common/BaseFormComponent";
import StaticField from "../common/StaticField";
import { Typography } from "@rmwc/typography";
import TextFieldGroup from "../common/TextFieldGroup";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../common/Loader";
import { Button } from '@rmwc/button';
import CheckboxField from "../common/CheckboxField";
import { putContacts } from "../../actions/accountActions";
import PhoneFieldGroupWithFlags from "../common/PhoneFieldGroupWithFlags";
import AlertMessage from "../common/AlertMessage";
import SelectField from "../common/SelectField";
import auth from "../../auth/authenticate";
import BackButton from "../common/BackButton";
import { SettingsPath } from "../../const/routes";
import Icon from "../common/Icon";
import { Language } from "../../const/language";

class ContactChange extends BaseFormComponent {
    static FieldContactMobile = "contact.mobile";
    static FieldContactEmail = "contact.email";
    static FieldMarketing = "contact.marketingAcceptance";
    static FieldFirstName = "contact.firstName";
    static FieldLastName = "contact.lastName";
    static FieldLanguage = "contact.language";
    static FieldHasNoEmail = "hasNoEmail";

    constructor(props) {
        super(props);

        let langValue;
        let selectedLang;

        if (this.props.contact !== null) {
            selectedLang = Language.find(c => this.props.contact.language === c.key) || Language[0];
            langValue = selectedLang ? selectedLang.value : Language[0].value;
        }

        this.state = {
            contact: props.contact || {},
            showChangeName: this.props.contact && (!this.props.contact.firstName || this.props.contact.firstName.length < 2),
            selectedLang: selectedLang,
            langValue: langValue,
            role: auth.getRole(),
            isAgent: auth.isAgent(),
            hasNoEmail: auth.isAgent() && auth.getRoleHasNoEmail() && !props.contact.email, // can only be set in agent view
            showNoEmailError: false
        };

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale,

            validators: {
                phoneformat: {
                    message: this.props.intl.formatMessage({ id: 'Error.Validator.Phoneformat' }),
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^\d{7,14}$/i)
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                },
                isRequiredIf: {
                    required: true,
                    rule: (val, params, validator) => {
                        let condition = params[0];
                        if (condition === 'false') {
                            return true;
                        }

                        return !validator.helpers.isBlank(val);
                    },
                    message: ""
                }
            }
        });

        this.submit = this.submit.bind(this);
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onClickUserHasNoEmail = this.onClickUserHasNoEmail.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.contact && (!prevProps.contact || prevProps.contact.crmId !== this.props.contact.crmId)) {
            this.setState({
                contact: this.props.contact,
                showChangeName: !this.props.contact.firstName || this.props.contact.firstName.length < 2
            });
        }
    }

    submit() {
        this.setState({ showNoEmailError: false });
        if (this.props.isPutContactsLoading) {
            return;
        }

        if (this.state.isAgent && !this.state.contact.email && !this.state.hasNoEmail) {
            this.setState({ showNoEmailError: true });
            return;
        }

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        if (this.state.isAgent) {
            if (this.state.hasNoEmail) {
                auth.setRoleHasNoEmail();
            } else {
                auth.clearRoleHasNoEmail();
            }
        }

        this.state.contact.contactDataRenewal = new Date(); // actual update date is set in backend
        this.props.putContacts({
            email: this.state.contact.email.trim(),
            phoneMobile: this.state.contact.mobile,
            marketingAcceptance: this.state.contact.marketingAcceptance,
            firstName: this.state.contact.firstName,
            lastName: this.state.contact.lastName,
            id: this.state.contact.crmId,
            groupId: this.props.changeAccountNameGroupId || null,
            language: this.state.selectedLang.key,
            isNewUser: auth.getUserData()?.isNewUser || false
        })
            .then(this.props.finish);
    }

    onChangeDropdown(afterChange, field, e) {
        let index = e.detail.index;
        let stateCopy = Object.assign({}, this.state);
        let selectedLang = Language[index];
        let langValue = selectedLang ? selectedLang.value : null;
        stateCopy.langValue = langValue;
        stateCopy.selectedLang = selectedLang;
        this.setState(stateCopy);
    }

    onChangeEmail(e, callback) {

        if (!this.state.isAgent) {
            this.onChange(e, callback);
        } else {
            let value = e.target.value;
            this.setState((prev) => ({
                hasNoEmail: !value,
                contact: {
                    ...prev.contact,
                    email: value
                }
            }));
        }
    }

    onClickUserHasNoEmail() {
        this.setState((prev) => {
            let hasNoEmailChecked = !prev.hasNoEmail;
            return {
                ...prev,
                hasNoEmail: hasNoEmailChecked,
                contact: {
                    ...prev.contact,
                    email: hasNoEmailChecked ? "" : prev.contact.email
                },
                showNoEmailError: !hasNoEmailChecked && !prev.contact.email
            };
        });
    }

    createListItems(selected) {
        let options = Language ? Language.map(
            c => {
                return ({
                    selected: c.value === selected,
                    value: c.value,
                    label: this.props.intl.formatMessage({ id: c.value })
                })
            }
        ) : [];

        return options;
    }

    render() {
        let createdListItems = this.createListItems(this.state.langValue);

        return (
            <>
                {this.props.contact.passive ?
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id="Error.Validator.PassiveUser" />}
                        isSmall={false}
                    /> :
                    <Grid className="mdc-layout-grid--base mdc-layout-grid--base-narrow">
                        {!this.state.isAgent &&
                            <GridCell span={12}>
                                <BackButton toPath={SettingsPath} />
                            </GridCell>
                        }
                        <GridCell span={12}>
                            <Typography
                                use="headline3"
                                className="mdc-typography mdc-theme--primary"
                            >
                                <FormattedMessage id={this.props.title} />
                            </Typography>
                        </GridCell>
                        {this.state.role.contactDataUpdateRequired &&
                            <GridCell span={12}>
                                <AlertMessage
                                    isSmall
                                    type={AlertMessage.TYPE_NOTICE}
                                    title={<FormattedMessage id="Settings.User.AcceptTermsTitle" />}
                                />
                            </GridCell>
                        }
                        {!this.state.showChangeName &&
                            <GridCell desktop={4} tablet={4}>
                                <StaticField
                                    value={`${this.state.contact.firstName} ${this.state.contact.lastName}`}
                                    label={
                                        <FormattedMessage id="Settings.User.Name" />
                                    }
                                />
                            </GridCell>
                        }
                        {this.state.showChangeName &&
                            <GridCell desktop={4} tablet={4}>
                                <TextFieldGroup
                                    field={ContactChange.FieldFirstName}
                                    validator={this.validator}
                                    rules="required"
                                    onChange={this.onChange}
                                    value={this.state.contact.firstName || ""}
                                    label="Settings.User.Name" />

                                <TextFieldGroup
                                    field={ContactChange.FieldLastName}
                                    validator={this.validator}
                                    rules="required"
                                    onChange={this.onChange}
                                    value={this.state.contact.lastName || ""}
                                    label="Settings.User.LastName" />
                            </GridCell>
                        }
                        <GridCell desktop={8} tablet={4}>
                            <StaticField
                                value={this.state.contact.idCode}
                                label={
                                    <FormattedMessage id="Settings.User.IdentificationCode" />
                                }
                            />
                        </GridCell>
                        <GridCell tablet={8} desktop={6}>
                            <TextFieldGroup
                                field={ContactChange.FieldContactEmail}
                                validator={this.validator}
                                rules={this.state.isAgent
                                    ? [
                                        `isRequiredIf:${!this.state.hasNoEmail}`,
                                        "email"
                                    ]
                                    : ["required", "email"]}
                                onChange={this.onChangeEmail}
                                value={this.state.contact.email?.trim() || ""}
                                label="Settings.User.Email" />
                            {this.state.isAgent &&
                                <>
                                    <CheckboxField
                                        nativeControlId="has-no-email"
                                        field={ContactChange.FieldHasNoEmail}
                                        checked={this.state.hasNoEmail || false}
                                        indeterminate={false}
                                        label={
                                            <label htmlFor="has-no-email">
                                                <FormattedMessage id="Settings.User.Terms.HasNoEmail.Checkbox" />
                                            </label>
                                        }
                                        onChange={this.onClickUserHasNoEmail}
                                    />
                                    {this.state.showNoEmailError &&
                                        <div className="mdc-theme--error mt-5">
                                            <Icon name="alert-small" />
                                            <FormattedMessage id="Settings.User.Terms.HasNoEmail.Error" />
                                        </div>}
                                </>}
                        </GridCell>
                        <GridCell tablet={8} desktop={6} ></GridCell>
                        <GridCell tablet={8} desktop={6}>
                            <PhoneFieldGroupWithFlags
                                validator={this.validator}
                                rules="required|phoneformat"
                                field={ContactChange.FieldContactMobile}
                                onUpdate={this.updateState.bind(this)}
                                value={this.state.contact.mobile || ""}
                                label="Settings.User.Phone" />
                        </GridCell>
                        <GridCell desktop={6}></GridCell>
                        <GridCell tablet={8} desktop={6}>
                            <SelectField
                                field={ContactChange.FieldLanguage}
                                onChange={this.onChangeDropdown}
                                value={this.state.langValue}
                                className="mdc-select--outlined"
                                options={createdListItems}
                                isLoading={false}
                                disabled={false}
                                label="Settings.User.Language"
                            />
                        </GridCell>
                        <GridCell tablet={8} desktop={12}>
                            <Typography
                                use="headline3"
                                className="mdc-typography mdc-theme--primary mt-20"
                            >
                                <FormattedMessage id="Settings.User.TermsAndAcceptance" />
                            </Typography>
                        </GridCell>
                        <GridCell tablet={8} desktop={12}>
                            <CheckboxField
                                nativeControlId="marketing-accept"
                                field={ContactChange.FieldMarketing}
                                checked={this.state.contact.marketingAcceptance || false}
                                indeterminate={false}
                                label={
                                    <label htmlFor="marketing-accept">
                                        <FormattedMessage id="Settings.User.Terms.AcceptNotifications.Checkbox" />
                                    </label>
                                }
                                onChange={this.onChangeCheckbox}
                            />
                            <div
                                className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent information__description lighter medium">
                                <FormattedMessage id="Settings.User.Terms.AcceptNotifications.Details"
                                    values={{
                                        additionalLink: <a
                                            href={this.props.intl.formatMessage({ id: "Settings.Privacy.AlexelaUrl" })} target="_blank" rel="noopener noreferrer">
                                            <FormattedMessage id="Settings.User.Terms.Link.Here" />
                                        </a>
                                    }} />
                            </div>
                        </GridCell>
                        <GridCell tablet={8} desktop={12}>
                            <div
                                className="medium mb-25">
                                <FormattedMessage id="Settings.User.AcceptTerms.Description"
                                    values={{
                                        location: <a
                                            href={this.props.intl.formatMessage({ id: "Settings.Privacy.AlexelaUrl" })} target="_blank" rel="noopener noreferrer">
                                            <FormattedMessage id="Settings.User.AcceptTerms.Description.Link" />
                                        </a>
                                    }} />
                            </div>

                            {this.props.putContactsError && this.errors(this.props.putContactsError, "Settings.User.Error")}

                            <Button
                                unelevated
                                onClick={this.submit}>
                                {this.props.isPutContactsLoading ?
                                    <Loader type={Loader.TYPE_BUTTON} />
                                    :
                                    <>
                                        <i className="icon-floppy" />
                                        <FormattedMessage id="Settings.User.Save" />
                                    </>
                                }
                            </Button>
                        </GridCell>
                        <GridCell desktop={2}></GridCell>
                    </Grid >
                }</>
        );
    }
}

function mapStateToProps(state) {
    return {
        isPutContactsLoaded: state.putContacts.fetched,
        putContactsError: state.putContacts.error,
        isPutContactsLoading: state.putContacts.fetching
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        putContacts
    }, dispatch
    );
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(ContactChange)
);