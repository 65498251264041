import React from "react";
import ContractAdditionalService from "./ContractAdditionalService";
import { useIntl, FormattedMessage } from "react-intl";
import { ChargingServiceTypes, EvChargerSupply } from "../../const/evChargingItems";

const EvCharger = ({ charger }) => {
    const intl = useIntl();

    const getSupplyTypeTranslation = () => {
        switch (charger?.supplyType) {
            case EvChargerSupply.Rent:
                return intl.formatMessage({ id: "Contract.EVCharger.ProductRent"});
            case EvChargerSupply.Sale:
                return intl.formatMessage({ id: "Contract.EVCharger.ProductSale"});
            case EvChargerSupply.Personal:
                return intl.formatMessage({ id: "Contract.EVCharger.ProductPersonal"});
            default:
                return "-";
        }
    }

    const renderProductWithPriceLabel = () => {
        return (
            <div className="ev-charger-item">
                <span className="text-bold mr-10">{getSupplyTypeTranslation()}:</span>
                <span className="mr-10">{charger?.productName}</span>
                {charger?.supplyType !== EvChargerSupply.Personal &&
                    <span className={charger?.customPrice ? "text-line-through" : ""}>
                        {intl.formatMessage({ id: "Contract.EVCharger.LinePrice" }, { price: charger?.price })}
                    </span>
                }
                {charger?.customPrice &&
                    <span> / {intl.formatMessage({ id: "Contract.EVCharger.DiscountPrice" }, { price: charger?.customPrice })}</span>
                }
                {renderServiceType()}
                {renderQuantity()}
            </div>
        );
    }

    const renderServiceType = () => {
        const chargingServiceType = ChargingServiceTypes.find(c => c.type === charger?.evChargingServiceTypeXc);
        if (!chargingServiceType) {
            return null;
        }
        return (
            <div>
                <span className="text-bold mr-10">{intl.formatMessage({ id: "Contract.EVCharger.ServiceType.Select.Title" })}:</span>{intl.formatMessage({ id: chargingServiceType.value })}
            </div>
        );
    }

    const renderQuantity = () => {
        return (
            <div>
                <span className="text-bold mr-10">{intl.formatMessage({ id: "Contract.EVCharger.Quantity" })}:</span>{charger?.quantity}
            </div>
        );
    }

    const renderAccessory = (accessory) => {
        return (
            <div>
                <div className="text-bold">{accessory?.productName}</div>
                <div>
                    <span>
                        <strong>
                            <FormattedMessage id="Contract.EVCharger.Quantity" />:
                        </strong>&nbsp;{accessory?.quantity}
                    </span>
                </div>
                {charger?.supplyType !== EvChargerSupply.Personal &&
                    <span className={charger?.customPrice ? "text-line-through" : ""}>
                        {intl.formatMessage({ id: "Contract.EVCharger.LinePrice" }, { price: charger?.price })}
                    </span>
                }
                {charger?.customPrice &&
                    <span> / {intl.formatMessage({ id: "Contract.EVCharger.DiscountPrice" }, { price: charger?.customPrice })}</span>
                }
                {accessory?.options?.map(option => {
                    return <ContractAdditionalService key={option.productId} service={option} />;
                })}
            </div>
        );
    }

    return (
        <div className="d-flex flex-dir-column">
            {renderProductWithPriceLabel()}
            <div className="additional-services-wrapper">
                {charger?.options?.map(option => {
                    return <ContractAdditionalService key={option.productId} service={option} />;
                })}
            </div>
            {charger?.accessories?.length > 0 && (
                <div className="accessories">
                    <strong><FormattedMessage id="Contract.EVCharger.Accessories" /></strong>
                    {charger?.accessories?.map(accessory => renderAccessory(accessory))}
                </div>
            )}
        </div>
    );  
}

export default EvCharger;