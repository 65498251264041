import React from "react";
import { fetchAllBonuscardTransactions } from "../../actions/transactionActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "@rmwc/button";
import exportToExcel from "../../components/common/ExcelExport";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";

class BonuscardTransactionsExport extends React.Component {
    static MAX_TRANSACTIONS = 1000;

    constructor(props) {
        super(props);
        this.export = this.export.bind(this);
        this.fetchAllTransactions = this.fetchAllTransactions.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.exportTransactionsFetching && this.props.exportTransactionsFetched && prevProps.exportTransactionsFetching) {
            this.export();
        }
    }

    fetchAllTransactions() {
        let filter = this.props.filter;
        this.props.fetchAllBonuscardTransactions(
            BonuscardTransactionsExport.MAX_TRANSACTIONS,
            filter.startDate.format("YYYY-MM-DD"),
            filter.endDate.format("YYYY-MM-DD"),
            filter.bonuscard,
            filter.servicePoint === undefined || filter.servicePoint == null ? "" : filter.servicePoint.value
        );
    }

    get columns() {
        return [
        {
            field: 'transactionTime',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.DateTime" }),
        },
        {
            field: 'cardNumber',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Card" }),
        },
        {
            field: 'cardName',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.CardName" }),
        },
        {
            field: 'station',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.ServicePoint" }),
        },
        {
            field: 'product',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Product" }),
        },
        {
            field: 'quantity',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Quantity" }),
        },
        {
            field: 'productUnit',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Unit" }),
        },
        {
            field: 'sum',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Sum" }),
        },
    ]};

    export() {
        const transactions = this.props.exportTransactions.map((transaction) => {
            return transaction.products.map((product) => ({
                transactionTime: moment(transaction.transactionDate).format("DD.MM.YYYY HH:mm"),
                cardNumber: transaction.cardNumber,
                cardName: transaction.cardName,
                station: transaction.station,
                product: product.productName,
                quantity: product.quantity,
                productUnit: product.productUnit,
                sum: transaction.sum,
            }));
        }).flat();

        exportToExcel(this.columns, transactions, "transactions.xlsx");
    }

    render() {
        return (
            <Button
                className="hidden-mobile mdc-button--outlined filter-form__export"
                outlined
                onClick={this.fetchAllTransactions}
            >
                <i className="icon-download" />
                <FormattedMessage id="Transactions.Export" />
            </Button>)
    }
}

function mapStateToProps(state) {
    return {
        exportTransactionsFetching: state.allBonuscardTransactions.fetching,
        exportTransactionsFetched: state.allBonuscardTransactions.fetched,
        exportTransactions: state.allBonuscardTransactions.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchAllBonuscardTransactions
        },
        dispatch
    );
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(BonuscardTransactionsExport)
);