import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";
import BonuscardTransactionFilter from "../../components/transactions/BonuscardTransactionFilter";
import { fetchBonuscardTransactions } from "../../actions/transactionActions";
import AlertMessage from "../../components/common/AlertMessage";
import "../../styles/react-components/table.scss";
import "../../styles/blocks/_filter-form.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../components/common/Loader";
import moment from "moment";
import Pagination from "../../components/common/Pagination";
import BonuscardTransactionsExport from "../../components/transactions/BonuscardTransactionsExport";
import BonuscardTransactionRow from "../../components/transactions/BonuscardTransactionRow";
import auth from "../../auth/authenticate";

import { getTransactionsBonusCardFilter, setTransactionsBonusCardFilter } from "../../cachedFilter";

class BonuscardTransactionContainer extends React.Component {
  static TRANSACTIONS_PER_PAGE = 10;

  constructor(props) {
    super(props);

    this.toggleFilter = this.toggleFilter.bind(this);
    this.onFilterSubmit = this.onFilterSubmit.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.renderTransactionRow = this.renderTransactionRow.bind(this);
    this.scrollToRef = React.createRef();

    const cachedFilter = getTransactionsBonusCardFilter();

    this.initialState = {
      filterOpen: true,
      filter: {
        startDate: moment().subtract(4, "months").startOf("month"),
        endDate: moment(),
        bonuscard: cachedFilter.bonuscard,
        servicePoint: cachedFilter.servicePoint,
        selectedPeriodPreset: cachedFilter.selectedPeriodPreset,
        selectedPeriodPresetName: cachedFilter.selectedPeriodPresetName
      },
      selectedPage: 1,
      selectedPageSize: auth.getPageSize() || BonuscardTransactionContainer.TRANSACTIONS_PER_PAGE,
      openedTransactions: []
    };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.fetchTransactions(this.state.selectedPage, this.state.selectedPageSize, this.state.filter);
  }

  toggleFilter = () => {
    this.setState(prevState => ({ filterOpen: !prevState.filterOpen }));
  };

  onFilterSubmit(filter) {
    let page = 1;
    this.setState({ selectedPage: page, filter: filter });
    this.fetchTransactions(page, this.state.selectedPageSize, filter);
    setTransactionsBonusCardFilter(filter);
  }

  fetchTransactions(page, pageSize, filter) {
    this.props.fetchBonuscardTransactions(
      page,
      pageSize,
      filter.startDate.format("YYYY-MM-DD"),
      filter.endDate.format("YYYY-MM-DD"),
      filter.bonuscard,
      filter.servicePoint === undefined || filter.servicePoint == null ? "" : filter.servicePoint.value
    );
  }

  onPageChange({ page, pageSize }) {
    this.setState(
      { selectedPage: page, selectedPageSize: pageSize },
      this.fetchTransactions.bind(this, page, pageSize, this.state.filter)
    );
  }

  renderTransactionsHeader() {
    const showFilter = this.state.filterOpen;

    return (
      <>
        <GridCell span={12}>
          <BonuscardTransactionsExport filter={this.state.filter} />
          <Button
            className={classnames(
              "mdc-button--secondary mdc-button--text uppercase other filter-form__link",
              { active: showFilter }
            )}
            onClick={this.toggleFilter}
            disabled={false}
            icon={{
              icon: "angle-down",
              strategy: "className",
              basename: "icon",
              prefix: "icon-",
            }}
          >
            <FormattedMessage id="Transactions.Filter" />
          </Button>

        </GridCell>
        <GridCell span={12}>
          <BonuscardTransactionFilter
            open={showFilter}
            toggle={this.toggleFilter}
            onSubmit={this.onFilterSubmit}
            filter={this.state.filter}
          />
        </GridCell>
        <GridCell span={12}>
          {this.renderTransactionMessages()}
        </GridCell>
      </>
    );
  }

  renderTransactionMessages = () => {
    const transactionsNotFound = !this.props.transactionsFetching && this.props.transactionsFetched &&
      (!this.props.totalTransactions || this.props.totalTransactions < 1);

    return (
      <>
        {transactionsNotFound &&
          <AlertMessage
            type={AlertMessage.TYPE_NOTICE}
            title={<FormattedMessage id="Transactions.NotFound" />}
          />
        }
      </>
    )
  };

  renderTransactionsList() {
    const transactions = this.props.transactions || [];
    return (
      <div className="table--transactions">
        <table className="table table--primary">
          <thead className="hidden-mobile">
            <tr className="table-heading">
              <th className="w-12">
                <FormattedMessage id="Transactions.Table.DateTime" />
              </th>
              <th className="w-21">
                <FormattedMessage id="Transactions.Table.Card" />
              </th>
              <th>
                <FormattedMessage id="Transactions.Table.CardName" />
              </th>
              <th>
                <FormattedMessage id="Transactions.Table.ServicePoint" />
              </th>
              <th className="text-right pull-right">
                <FormattedMessage id="Transactions.Table.Sum" />
              </th>
              <th>
                <FormattedMessage id="Transactions.Table.DigitalStamps" />
              </th>
              <th>
                <FormattedMessage id="Transactions.Table.Receipt" />
              </th>
              <th>
              </th>
            </tr>
          </thead>
          <tbody className="table--card-body">
            {transactions.map(this.renderTransactionRow)}
            </tbody>
        </table>
      </div>
    );
  }

  renderTransactionRow(transaction, index) {
    return (
      <BonuscardTransactionRow key={"transaction" + index} transaction={transaction}/>
    );
  }

  render() {
    const hasTransactions = this.props.totalTransactions > 0;

    return (
      <Grid className="mdc-layout-grid--base mb-60">
        {this.renderTransactionsHeader()}
        <GridCell span={12} ref={this.scrollToRef}>
          {this.props.transactionsFetching ?
            <Loader type={Loader.TYPE_CENTER} />
            : (hasTransactions && this.renderTransactionsList(this.props.transactions))
          }
        </GridCell>
        <GridCell span={12}>
          <Pagination
            total={this.props.totalTransactions || 0}
            selectedPage={this.state.selectedPage}
            onChange={this.onPageChange}
            scrollToRef={this.scrollToRef}
          />
        </GridCell>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionsFetching: state.bonuscardTransactions.fetching,
    transactionsFetched: state.bonuscardTransactions.fetched,
    transactions: state.bonuscardTransactions.data,
    totalTransactions: state.bonuscardTransactions.total,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBonuscardTransactions,
    },
    dispatch
  );
}

export default injectIntl(
  connect(mapStateToProps, matchDispatchToProps)(BonuscardTransactionContainer)
);
