import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "./BreadCrumbs";
import { Grid, GridCell } from "@rmwc/grid";
import React, { useEffect } from "react";
import Banner from "./Banner";
import { fetchBanner, setBannerHidden } from "../../actions/bannerActions";
import {IsLatvianWeb} from "../../helpers/AlternativeDesign";
import {useFeature} from "flagged";
import {LotteryCampaign} from "../../const/featureFlags";
import CampaignBanner from "./CampaignBanner";
import {getActiveTicketCount} from "../../actions/campaignActions";

const HeroSection = ({ showBanner, title }) => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const isLotteryCampaign = useFeature(LotteryCampaign);
    const dispatch = useDispatch();

    const {
        data: banner,
        fetched: bannerFetched,
        fetching: bannerFetching
    } = useSelector(state => state.banner);

    useEffect(() => {
        if (!IsLatvianWeb() && showBanner === true && isLoggedIn) {
            dispatch(getActiveTicketCount())
            dispatch(fetchBanner());
        }
    }, [dispatch, showBanner, isLoggedIn]);

    const hideBanner = () => {
        dispatch(setBannerHidden()).then(() => dispatch(fetchBanner()));
    }

    const renderBreadCrumbs = () => {
        return (
            <GridCell span={12}>
                <BreadCrumbs />
            </GridCell>
        );
    }

    const renderBanner = () => {
        if (!bannerFetched || bannerFetching)
            return;

        if (showBanner && isLotteryCampaign)
            return <CampaignBanner/>

        if (showBanner && !banner.isBannerHidden)
            return <Banner onHide={() => hideBanner()}/>;
    }

    const renderTitle = () => {
        return (
            <Grid>
                {renderBreadCrumbs()}
                <GridCell span={12} className="hero--title">
                    {!!title && title}
                </GridCell>
            </Grid>
        );
    }

    return (
        <section>
            <div className="hero">
                {renderBanner()}
                {renderTitle()}
            </div>
        </section>
    )
}

export default HeroSection;