import React from "react";
import {FormattedMessage,} from 'react-intl';
import { Grid, GridCell } from '@rmwc/grid';
import { Typography } from "@rmwc/typography";
import { ServicePath } from "../const/routes";
import { Button } from "@rmwc/button";

class ContractThankYou extends React.Component{

    render() {
        return (
            <Grid>
                <GridCell span={8}>
                    <Typography use="headline3 mb-30">
                        <FormattedMessage id="Contract.ThankYou.TitleEmail" />
                    </Typography>
                    <Typography use="body1">
                        <FormattedMessage id="Contract.ThankYou.InfoEmail" />
                    </Typography>
                    <Button
                        className="mdc-button--secondary mt-30"
                        outlined
                        tag="a"
                        href={ServicePath}
                    >
                        <FormattedMessage id="Contract.ThankYou.BackToServices" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}

export default ContractThankYou;