import { Grid, GridCell, GridInner } from "@rmwc/grid";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SummerCampaignPrize, SummerCampaignTabs, SummerCampaignTabsMap } from "../../const/summerCampaign";
import { getPrizeTypeByXc, isRedirectablePrize } from "../../helpers/summerCampaignHelper";
import { getActiveTicketCount, spinWheel, clearSpinWheelResult } from "../../actions/campaignActions";
import AlertMessage from "../common/AlertMessage";

import PrizeModal from "./PrizeModal";
import SpinButton from "./SpinButton";

import WheelPointer from "../../images/summer_campaign/wheel_pointer.png";
import WheelPointerBg from "../../images/summer_campaign/wheel_pointer_bg.png";
import WheelEst from "../../images/summer_campaign/wheel_est.png";
import WheelEng from "../../images/summer_campaign/wheel_eng.png";
import WheelRus from "../../images/summer_campaign/wheel_rus.png";

const prizePositionMap = [
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.Money100,
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.Beans,
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.Money10,
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.FreeCoffee,
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.Money2,
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.FreeCoffeeYear,
    SummerCampaignPrize.TryAgain,
    SummerCampaignPrize.Money20,
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.Money5,
    SummerCampaignPrize.NoWin,
    SummerCampaignPrize.Cup,
];

const getRandomNoWinPosition = () => {
    const noWinArray = prizePositionMap
        .map((item, i) => item === SummerCampaignPrize.NoWin ? i : -1)
        .filter(i => i !== -1);
    return noWinArray[Math.floor(Math.random() * noWinArray.length)];
}

const WheelOfFortune = ({ onRedirectToTab }) => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [isPrizeModalOpen, setIsPrizeModalOpen] = useState(false);
    const [isLoadingPrize, setIsLoadingPrize] = useState(false);
    const [currentPrize, setCurrentPrize] = useState();

    const isMobile = useSelector(state => state.ui.isMobileOrTabletView);
    const activeTicketCount = useSelector(state => state.activeTicketCount);
    const spinWheelState = useSelector(state => state.spinWheel);

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActiveTicketCount());
    }, [dispatch]);

    useEffect(() => {
        if (spinWheelState.fetched && !spinWheelState.fetching && !spinWheelState.error) {
            setIsLoadingPrize(false);
            setCurrentPrize(getPrizeTypeByXc(spinWheelState.data));
            setIsSpinning(true);
        }
    }, [dispatch, spinWheelState]);

    const wheelImage = useMemo(() => {
        switch (intl.locale) {
            case "et":
                return WheelEst;
            case "en":
                return WheelEng;
            case "ru":
                return WheelRus;
            default:
                return WheelEst;
        }
    }, [intl]);

    const onFinishedSpinning = () => {
        setIsPrizeModalOpen(true);
        dispatch(getActiveTicketCount());
    }

    const onCloseModal = (isRedirect) => {
        dispatch(clearSpinWheelResult());
        setIsPrizeModalOpen(false);
        setIsSpinning(false);

        if (isRedirect && isRedirectablePrize(currentPrize)) {
            onRedirectToTab(SummerCampaignTabsMap[SummerCampaignTabs.Prizes]);
        }
    }

    const onSpinWheel = () => {
        setIsLoadingPrize(true);
        dispatch(spinWheel());
    }

    const hasActiveTickets = useMemo(() => {
        return activeTicketCount.data > 0;
    }, [activeTicketCount.data]);

    const prizePosition = () => {
        if (!spinWheelState.fetched) {
            return "359deg";
        }

        const prizeType = getPrizeTypeByXc(spinWheelState.data ?? SummerCampaignPrize.NoWin);
        const prizePosition = prizeType === SummerCampaignPrize.NoWin
            ? getRandomNoWinPosition()
            : prizePositionMap.indexOf(prizeType);

        const spinCount = 5;
        const offset = (360 / prizePositionMap.length) * prizePosition;
        return `${360 * spinCount - offset}deg`;
    };

    const renderSpinArrows = () => {
        if (isMobile) {
            return null;
        }

        return (
            <div className="arrows">
                {[...Array(5).keys()].map((index) =>
                    <i key={index} className={classNames("icon icon-angle-right", {
                        "disabled": !hasActiveTickets,
                        "loading": isLoadingPrize
                    })} />
                )}
            </div>
        );
    }

    const renderBadge = () => {
        return (
            <div className={classNames("summer-campaign__prize-overview_badge mt-10", {
                "disabled": !hasActiveTickets
            })}>
                <FormattedMessage id="SummerCampaign.Wheel.Badge" values={{
                    amount: activeTicketCount.data ?? 0
                }} />
            </div>
        );
    }

    const renderTimeLeft = () => {
        if (hasActiveTickets) {
            return null;
        }

        return (
            <div className="time-left">
                <FormattedMessage id="SummerCampaign.Wheel.TimeLeft" />
            </div>
        );
    }

    const renderDisclaimer = () => {
        return (
            <div className="disclaimer">
                <FormattedMessage id="SummerCampaign.Wheel.Disclaimer"/>
            </div>
        );
    }

    return (
        <>
            {(activeTicketCount.error || spinWheelState.error) &&
                <AlertMessage
                    className="mt-30"
                    type={AlertMessage.TYPE_ALERT}
                    title={intl.formatMessage({ id: "SummerCampaign.Error.LoadingData" })}
                />
            }
            <Grid className={classNames("summer-campaign__wheel mt-30", {
                "mobile": isMobile
            })}>
                {isMobile &&
                    <>
                        <GridCell span={12} className="wheel-badge-mobile">
                            {renderBadge()}
                        </GridCell>
                        <GridCell span={12} className="disclaimer-mobile">
                            {renderDisclaimer()}
                        </GridCell>
                    </>


                }
                <GridCell span={12} className="summer-campaign__wheel_wrapper mobile">
                    <GridInner>
                        <GridCell span={6} className={classNames("spin-buttons", {
                            "mobile": isMobile,
                            "disabled": !hasActiveTickets,
                            "spinning": isSpinning
                        })}>
                            <div className="spin-buttons_wrapper">
                                <div className="refresh-button mobile">
                                    <SpinButton
                                        onClick={onSpinWheel}
                                        isLoading={isLoadingPrize}
                                        isDisabled={isLoadingPrize || !hasActiveTickets || isSpinning}
                                    />
                                    {renderSpinArrows()}
                                </div>
                                {!isMobile && renderBadge()}
                                {renderTimeLeft()}
                            </div>
                        </GridCell>
                        <GridCell span={6} className={classNames("content", {
                            "mobile": isMobile
                        })}>
                            <img className="wheel-pointer" src={WheelPointer} alt="" />
                            <img className="wheel-pointer-bg" src={WheelPointerBg} alt="" />
                            <img
                                src={wheelImage}
                                alt="Wheel"
                                style={{
                                    "--spin-value": prizePosition()
                                }}
                                className={classNames("circle", {
                                    "spinning": isSpinning
                                })}
                                onAnimationEnd={onFinishedSpinning}
                            />
                            {!isMobile && renderDisclaimer()}
                        </GridCell>
                    </GridInner>
                </GridCell>
            </Grid>

            <PrizeModal
                isOpen={isPrizeModalOpen}
                onClose={() => onCloseModal(false)}
                onCloseWithRedirect={() => onCloseModal(true)}
                prize={currentPrize}
            />
        </>
    );
}

export default WheelOfFortune;