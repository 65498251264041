import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@rmwc/button";
import { Drawer, DrawerContent, DrawerTitle } from "@rmwc/drawer";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import auth from "../auth/authenticate";
import { useScrollToRef, useValidator } from "../helpers/hooks";
import IdCodeValidatorRule from "../validators/IdCodeValidatorRule";
import IsTrueConditionValidatorRule from "../validators/IsTrueConditionValidatorRule";
import logo from "../images/logo-white.svg";
import TextFieldGroup from "../components/common/TextFieldGroup";
import SelectField from "../components/common/SelectField";
import {
    DigitalStampsConverter,
    DigitalStampsHistory,
    DigitalStampsLoyaltyInfo,
    DigitalStampsClientCustomerCreationForm
} from "../components/DigitalStamps";
import {
    getDigitalStampsSummaryForClient,
    getContractSummaryClient,
    clearDigitalStampsSummaryForClient,
    clearDigitalStampsTransactionsForClient,
    getProductsClient,
    convertProductClient,
    getStations,
    getRecentElectricityInvoices,
    getBonuscardStatus,
    clearBonuscardStatus,
    getCampaignPrizesClient,
    redeemCampaignPrizeClient
} from "../actions/digitalStampsActions";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import SelectAutoCompleteField from "../components/common/SelectAutoCompleteField";
import { defaultIconProps } from "../components/common/Icon";

import "../styles/components/_digital-stamps-client.scss";

const WHITELISTED_IP_ADDRESSES = process.env.REACT_APP_WHITELISTED_IPS;

const PAGE_IDLE_TIMEOUT = 600000; // ms = 10 min
const TIMEOUT_CHECK_INTERVAL = 60000; // 1 min
const STATION_ID_KEY = "station_id";

const ALERT_CONVERSION_SUCCESS = "ALERT_CONVERSION_SUCCESS";
const ALERT_ADD_CUSTOMER_SUCCESS = "ALERT_ADD_CUSTOMER_SUCCESS";
const ALERT_CONVERT_PRODUCT_SUCCESS = "ALERT_CONVERT_PRODUCT_SUCCESS";
const ALERT_REDEEM_CAMPAIGN_PRIZE_SUCCESS = "ALERT_REDEEM_CAMPAIGN_PRIZE_SUCCESS";
const ALERT_ADD_CUSTOMER_EXISTS = "ALERT_ADD_CUSTOMER_EXISTS";
const ALERT_BIND_BONUSCARD_SUCCESS = "ALERT_BIND_BONUSCARD_SUCCESS";
const ALERT_BIND_BONUSCARD_USER_EXISTS = "ALERT_BIND_BONUSCARD_USER_EXISTS";
const ALERT_ERROR = "ALERT_ERROR";

const DigitalStampsClient = () => {
    const stampsSummary = useSelector(state => state.digitalStampsSummary);
    const contractSummary = useSelector(state => state.contracts);
    const postStamps = useSelector(state => state.convertStamps);
    const postAddCustomer = useSelector(state => state.postAddCustomer);
    const stations = useSelector(state => state.stations);
    const products = useSelector(state => state.digitalStampsProducts.data);
    const campaignPrizes = useSelector(state => state.digitalStampsCampaignPrizes);
    const convertProduct = useSelector(state => state.convertProductClient);
    const redeemCampaignPrize = useSelector(state => state.digitalStampsRedeemCampaignPrize);
    const bonuscardStatus = useSelector(state => state.bonuscardStatus);
    const postBindBonuscard = useSelector(state => state.postBindBonuscard);

    const [isValidIp, setIsValidIp] = useState(false);
    const [personalCode, setPersonalCode] = useState("");
    const [crmAccountId, setCrmAccountId] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedCampaignPrize, setSelectedCampaignPrize] = useState();
    const [alert, setAlert] = useState(null);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedStation, setSelectedStation] = useState();
    const [bonuscardNumber, setBonuscardNumber] = useState("");
    const [validator,] = useValidator({
        ...IdCodeValidatorRule.rule(),
        ...IsTrueConditionValidatorRule.rule()
    });
    const intl = useIntl();

    const [bonuscardValidator,] = useValidator({
        cardNotOpen: {
            rule: (val, params) => {
                if (params[0] === "false") return true;
                return params[1] === "true"

            }, // dont check card status if not fetched yet
            message: intl.formatMessage({ id: "DigitalStampsClient.Error.BonuscardNotOpen" })
        },
        status: {
            rule: (val, params) => params[0] === "null",
            message: intl.formatMessage({ id: "DigitalStampsClient.Error.CouldNotGetBonuscardStatus" })
        }
    });

    const alertRef = useRef();
    const scrollToRef = useScrollToRef();
    const dispatch = useDispatch();

    let time = new Date().getTime();

    const resetTimer = () => {
        time = new Date().getTime();
    }

    document.body.addEventListener("mousemove", resetTimer);
    document.body.addEventListener("keypress", resetTimer);

    const refreshPage = () => {
        if (new Date().getTime() - time >= PAGE_IDLE_TIMEOUT) {
            dispatch(clearPage());
        } else {
            setTimeout(refreshPage, TIMEOUT_CHECK_INTERVAL);
        }
    }

    setTimeout(refreshPage, TIMEOUT_CHECK_INTERVAL);

    useEffect(() => {
        const checkIp = async () => {
            const ipAddress = await auth.getClientIp();

            if (WHITELISTED_IP_ADDRESSES.split(",").indexOf(ipAddress) > -1) {
                setIsValidIp(true);
            }
        }

        checkIp().catch(err => console.log(err));
    }, []);

    const fetchData = useCallback(() => dispatch => {
        dispatch(getStations());
        dispatch(getProductsClient());
    }, []);

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch, fetchData]);

    useEffect(() => {
        const stateStation = localStorage.getItem(STATION_ID_KEY);
        if (stateStation) {
            const parsedValue = JSON.parse(stateStation);
            const value = typeof parsedValue === "object" && !Array.isArray(parsedValue) && parsedValue !== null
                ? parsedValue
                : { label: "", value: "" }
            setSelectedStation(value);
        }
    }, [stations.data])

    useEffect(() => {
        setSelectedProduct(products[0]?.id || null);
    }, [products]);

    useEffect(() => {
        if (stampsSummary.data?.crmAccountId && crmAccountId !== stampsSummary.data?.crmAccountId) {
            dispatch(getContractSummaryClient(stampsSummary.data.crmAccountId, personalCode));
            dispatch(getRecentElectricityInvoices(stampsSummary.data.crmAccountId));
            dispatch(getCampaignPrizesClient(stampsSummary.data?.crmAccountId))
            setCrmAccountId(stampsSummary.data?.crmAccountId);
        }
    }, [dispatch, stampsSummary]);

    useEffect(() => {
        if (redeemCampaignPrize.fetched) {
            dispatch(getCampaignPrizesClient(stampsSummary.data?.crmAccountId))
        }
    }, [dispatch, redeemCampaignPrize]);

    useEffect(() => {
        const postStampsOk = !postStamps.fetching && postStamps.fetched && !postStamps.error;
        const postAddCustomerOk = !postAddCustomer.fetching && postAddCustomer.fetched && !postAddCustomer.error;
        const convertProductOk = !convertProduct.fetching && convertProduct.fetched && !convertProduct.error;
        const redeemCampaignPrizeOk = !redeemCampaignPrize.fetching && redeemCampaignPrize.fetched && !redeemCampaignPrize.error;
        const postBindBonuscardOk = !postBindBonuscard.fetcing && postBindBonuscard.fetched && !postBindBonuscard.error;

        if (postStampsOk || postAddCustomerOk || convertProductOk || postBindBonuscardOk || redeemCampaignPrizeOk) {
            dispatch(getDigitalStampsSummaryForClient(personalCode));

            if (postStampsOk) {
                setAlert(ALERT_CONVERSION_SUCCESS);
            } else if (postAddCustomerOk) {
                setAlert(postAddCustomer.data?.isNewUser
                    ? ALERT_ADD_CUSTOMER_SUCCESS
                    : ALERT_ADD_CUSTOMER_EXISTS);
            } else if (postBindBonuscardOk) {
                setAlert(postBindBonuscard.data?.isNewUser
                    ? ALERT_BIND_BONUSCARD_SUCCESS
                    : ALERT_BIND_BONUSCARD_USER_EXISTS);
            } else if (convertProductOk) {
                setAlert(ALERT_CONVERT_PRODUCT_SUCCESS);
            } else if (redeemCampaignPrizeOk) {
                setAlert(ALERT_REDEEM_CAMPAIGN_PRIZE_SUCCESS);
            }
        }

        if (postStamps.error || postAddCustomer.error || convertProduct.error || postBindBonuscard.error || redeemCampaignPrize.error) {
            setAlert(ALERT_ERROR);
        }
    }, [dispatch, postStamps, postAddCustomer, convertProduct, postBindBonuscard, redeemCampaignPrize]);

    const onSearchPersonalCode = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        dispatch(getDigitalStampsSummaryForClient(personalCode));
        setAlert(null);
        resetTimer();
    }

    const clearPage = () => dispatch => {
        dispatch(clearDigitalStampsSummaryForClient());
        dispatch(clearDigitalStampsTransactionsForClient());
        setAlert(null);
    }

    const onClearPage = () => {
        dispatch(clearPage());
        resetTimer();
    }

    const onRedeemCampaignPrize = () => {
        if (selectedCampaignPrize !== null) {
            const data = {
                prizeType: selectedCampaignPrize,
                crmAccountId: crmAccountId,
            }
            dispatch(redeemCampaignPrizeClient(data));
        }
    }

    const onUseProduct = () => {
        if (selectedProduct !== null) {
            const data = {
                productId: selectedProduct,
                crmAccountId: crmAccountId,
                stationId: selectedStation.value
            }
            dispatch(convertProductClient(data));
        }
    }

    const onClearCampaignPrize = () => {
        setSelectedCampaignPrize(campaignPrizes.data[0]?.id || null);
    }

    const onClearProduct = () => {
        setSelectedProduct(products[0]?.id || null);
    }

    const onCreateNewCustomer = () => {
        const stationsField = intl.formatMessage({ id: "DigitalStampsClient.StationSelect" })?.toLowerCase();

        if (!validator.fieldValid(stationsField)) {
            validator.showMessageFor(stationsField);
            return;
        }

        setIsFormOpen(true);
    }

    const onSelectStation = (selection) => {
        setSelectedStation(selection);
        localStorage.setItem(STATION_ID_KEY, JSON.stringify(selection));
    }

    const renderStationSelection = () => {
        return (
            <GridInner>
                <GridCell span={10}>
                    <SelectAutoCompleteField
                        searchPlaceholder={intl.formatMessage({ id: "DigitalStampsClient.StationSelect.Placeholder" })}
                        value={selectedStation}
                        onChange={onSelectStation}
                        field="stations"
                        label="DigitalStampsClient.StationSelect"
                        options={stations.data.map(s => ({
                            label: s.station_name,
                            value: s.station_id
                        }))}
                        rules="required"
                        validator={validator}
                    />
                </GridCell>
                <GridCell span={2} align="bottom">
                    <Button unelevated onClick={onCreateNewCustomer}>
                        <FormattedMessage id="DigitalStampsClient.Button.CreateNewCustomer" />
                    </Button>
                </GridCell>
            </GridInner>
        );
    }

    const isBonuscardNumberEmpty = !bonuscardNumber || bonuscardNumber.length <= 0;

    const checkBonuscardFieldIsValid = () => {
        const field = intl.formatMessage({ id: "DigitalStampsClient.BonuscardNumber" })?.toLowerCase();

        if (!bonuscardValidator.allValid(field)) {
            bonuscardValidator.showMessageFor(field);
            return false;
        }
        return true;
    }

    const onBonuscardInputChange = (e) => {
        setBonuscardNumber(e.target.value);
        dispatch(clearBonuscardStatus());
    }

    const onValidateBonuscard = () => {
        if (!checkBonuscardFieldIsValid()) { return; }

        if (!bonuscardStatus.fetching) {
            dispatch(getBonuscardStatus(bonuscardNumber))
                .then(
                    _ => checkBonuscardFieldIsValid(),
                    _ => checkBonuscardFieldIsValid());
        }
    }

    const onBindBonuscard = () => {
        const stationsField = intl.formatMessage({ id: "DigitalStampsClient.StationSelect" })?.toLowerCase();

        if (!validator.fieldValid(stationsField)) {
            validator.showMessageFor(stationsField);
            return;
        }

        if (!checkBonuscardFieldIsValid()) { return; }

        setIsFormOpen(true);
    }

    const renderBonuscardNumberBinding = () => {
        const { fetching, fetched, error, data: { isCardOpen = false } } = bonuscardStatus;

        let iconProps = null;
        if (fetching) {
            iconProps = {
                strategy: "custom",
                render: () => <Loader type={Loader.TYPE_TEXT_FIELD} size="small" />
            }
        }
        else if (fetched && isCardOpen) {
            iconProps = { ...defaultIconProps, icon: "check-s", className: "mdc-theme--done" };
        }

        return (
            <GridInner>
                <GridCell span={8}>
                    <TextFieldGroup
                        field="bonuscardNumber"
                        onChange={onBonuscardInputChange}
                        rules={[
                            "numeric",
                            `cardNotOpen:${fetched},${isCardOpen}`,
                            `status:${error}`,
                            { min: 16 },
                            { max: 16 },
                        ]}
                        validator={bonuscardValidator}
                        value={bonuscardNumber}
                        validationLabel="DigitalStampsClient.BonuscardNumber"
                        label="DigitalStampsClient.BonuscardNumber"
                        disabled={fetching}
                        trailingIcon={iconProps} />
                    {/* { ...loadingIconProps, style: { marginTop: "-8px" } }} /> */}
                </GridCell>
                <GridCell span={2} className="mt-26 mt-tablet-clear">
                    <Button unelevated onClick={onValidateBonuscard} disabled={fetching || isBonuscardNumberEmpty}>
                        <FormattedMessage id="DigitalStampsClient.Button.ValidateBonuscard" defaultMessage="Valideeri" />
                    </Button>
                </GridCell>
                <GridCell span={2} className="mt-26 mt-tablet-clear">
                    <Button unelevated onClick={onBindBonuscard} disabled={fetching || isBonuscardNumberEmpty || !isCardOpen}>
                        <FormattedMessage id="DigitalStampsClient.Button.BindBonuscard" defaultMessage="Seo sooduskaart" />
                    </Button>
                </GridCell>
            </GridInner>
        );
    }

    const renderPersonalCodeSearch = () => {
        return (
            <>
                <GridCell span={12} className="digital-stamps-client--content--title">
                    <FormattedMessage id="DigitalStampsClient.Title.PersonalCode" />
                </GridCell>
                <GridInner className="digital-stamps-client--content-search">
                    <GridCell span={2} className="digital-stamps-client--content-search-text">
                        <FormattedMessage id="DigitalStampsClient.PersonalCode" />
                    </GridCell>
                    <GridCell span={6}>
                        <TextFieldGroup
                            field="personalCode"
                            onChange={e => setPersonalCode(e.target.value)}
                            validator={validator}
                            rules="required|idCode:EE"
                            value={personalCode}
                            dontTranslateLabel
                            noLabel
                            label={intl.formatMessage({ id: "DigitalStampsClient.PersonalCode" })}
                        />
                    </GridCell>
                    <GridCell span={2}>
                        <Button unelevated onClick={onSearchPersonalCode}>
                            <FormattedMessage id="DigitalStampsClient.Button.Search" />
                        </Button>
                    </GridCell>
                    <GridCell span={2}>
                        <Button outlined onClick={onClearPage}>
                            <FormattedMessage id="DigitalStampsClient.Button.Clear" />
                        </Button>
                    </GridCell>
                </GridInner>
            </>
        );
    }

    const renderCustomerInfo = () => {
        return (
            <>
                <GridCell span={12} className="digital-stamps-client--content--title">
                    <FormattedMessage id="DigitalStampsClient.Title.CustomerInfo" />
                </GridCell>
                <DigitalStampsLoyaltyInfo data={stampsSummary.data} isClient />
            </>
        );
    }

    const renderCampaignPrizeSelection = () => {
        const options = campaignPrizes.data?.length > 0
            ? campaignPrizes.data.map(prize => ({
                label: intl.formatMessage({id: `DigitalStampsClient.${prize.prizeTypeXc}`}, {amount: prize.amount}),
                value: prize.prizeTypeXc
            }))
            : [{label: intl.formatMessage({id: "DigitalStampsClient.No.Prizes"})}];

        return (
            <>
                <GridCell span={12} className="digital-stamps-client--content--title">
                    <FormattedMessage id="DigitalStampsClient.Title.SelectCampaignPrize" />
                </GridCell>
                <GridInner className="digital-stamps-client--content-products">
                    <GridCell span={8}>
                        <SelectField
                            field="products"
                            label="DigitalStampsClient.SelectPrize"
                            value={selectedCampaignPrize}
                            onChange={(afterChange, field, e) => setSelectedCampaignPrize(e.currentTarget.value)}
                            enhanced
                            menu
                            outlined
                            options={options}
                        />
                    </GridCell>
                    <GridCell span={2} align="bottom">
                        <Button unelevated onClick={onRedeemCampaignPrize}
                                disabled={campaignPrizes.data?.length <= 0 || redeemCampaignPrize.fetching}>
                            <FormattedMessage id="DigitalStampsClient.Button.Use"/>
                        </Button>
                    </GridCell>
                    <GridCell span={2} align="bottom">
                        <Button outlined onClick={onClearCampaignPrize}>
                            <FormattedMessage id="DigitalStampsClient.Button.Clear" />
                        </Button>
                    </GridCell>
                </GridInner>
            </>
        )
    }

    const renderProductSelection = () => {
        return (
            <>
                <GridCell span={12} className="digital-stamps-client--content--title">
                    <FormattedMessage id="DigitalStampsClient.Title.SelectGift" />
                </GridCell>
                <GridInner className="digital-stamps-client--content-products">
                    <GridCell span={8}>
                        <SelectField
                            field="products"
                            label="DigitalStampsClient.SelectProduct"
                            value={selectedProduct}
                            onChange={(afterChange, field, e) => setSelectedProduct(e.currentTarget.value)}
                            enhanced
                            menu
                            outlined
                            options={products.map(product => ({
                                label: `${product.name} (${product.stampCost} templit)`,
                                value: product.id
                            }))}
                        />
                    </GridCell>
                    <GridCell span={2} align="bottom">
                        <Button unelevated onClick={onUseProduct} disabled={convertProduct.fetching}>
                            <FormattedMessage id="DigitalStampsClient.Button.Use" />
                        </Button>
                    </GridCell>
                    <GridCell span={2} align="bottom">
                        <Button outlined onClick={onClearProduct}>
                            <FormattedMessage id="DigitalStampsClient.Button.Clear" />
                        </Button>
                    </GridCell>
                </GridInner>
            </>
        )
    }

    const renderAlertMessage = () => {
        if (!alert) return null;

        scrollToRef(alertRef);
        let successMessage = "";
        let type = AlertMessage.TYPE_DONE;

        if (alert === ALERT_CONVERSION_SUCCESS) {
            successMessage = "DigitalStampsClient.Alert.Success";
        }
        if (alert === ALERT_ADD_CUSTOMER_SUCCESS) {
            successMessage = "DigitalStampsClient.Alert.AddCustomerSuccess";
        }
        if (alert === ALERT_CONVERT_PRODUCT_SUCCESS) {
            successMessage = "DigitalStampsClient.Alert.ConvertProductSuccess";
        }
        if (alert === ALERT_ADD_CUSTOMER_EXISTS) {
            successMessage = "DigitalStampsClient.Alert.AddCustomerExists";
            type = AlertMessage.TYPE_WARN;
        }
        if (alert === ALERT_BIND_BONUSCARD_SUCCESS) {
            successMessage = "DigitalStampsClient.Alert.BindBonuscardSuccess";
        }
        if (alert === ALERT_BIND_BONUSCARD_USER_EXISTS) {
            successMessage = "DigitalStampsClient.Alert.BindBonuscardUserExists";
            type = AlertMessage.TYPE_WARN;
        }
        if (alert === ALERT_REDEEM_CAMPAIGN_PRIZE_SUCCESS) {
            successMessage = "DigitalStampsClient.Alert.RedeemCampaignPrizeSuccess";
        }

        if (successMessage) {
            return (
                <AlertMessage
                    type={type}
                    title={intl.formatMessage({ id: successMessage })}
                />
            );
        }

        let errorMessage = "";
        if (postStamps.error) {
            errorMessage = "DigitalStampsClient.Alert.ConvertError";
        }
        if (stampsSummary.error) {
            errorMessage = "DigitalStampsClient.Alert.UserNotFoundError";
        }
        if (contractSummary.error) {
            errorMessage = "DigitalStampsClient.Alert.GetDataError";
        }
        if (postAddCustomer.error) {
            errorMessage = "DigitalStampsClient.Alert.AddCustomerError";
        }
        if (convertProduct.error) {
            errorMessage = "DigitalStampsClient.Alert.ConvertProductError";
        }
        if (postBindBonuscard.error) {
            errorMessage = "DigitalStampsClient.Alert.BindBonuscardError";
        }
        if (redeemCampaignPrize.error) {
            errorMessage = "DigitalStampsClient.Alert.RedeemCampaignPrizeError";
        }

        if (errorMessage) {
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_ALERT}
                    title={intl.formatMessage({ id: errorMessage })}
                />
            );
        }

        return null;
    }

    const renderHistory = () => {
        if (!personalCode) {
            return null;
        }

        return (
            <div className="digital-stamps-client--content-history">
                <DigitalStampsHistory personalCode={personalCode} isClient />
            </div>
        )
    }

    const onCloseNewCustomerDialog = (ssn) => {
        setPersonalCode(ssn);
        setIsFormOpen(false);
    }

    const renderNewCustomerDrawer = () => {
        // Pretty much a hack, because it is not possible to clear the validation state between re-renders
        if (!isFormOpen) return null;

        return (
            <Drawer modal open={isFormOpen} onClose={() => setIsFormOpen(false)} className="form">
                <DrawerTitle>
                    <Button icon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "close-icon"
                    }} onClick={() => setIsFormOpen(false)}
                        className="form--btn" />
                </DrawerTitle>
                <DrawerContent>
                    <DigitalStampsClientCustomerCreationForm
                        personalCode={personalCode}
                        stationId={selectedStation.value}
                        bonuscardNumber={bonuscardNumber}
                        onClose={onCloseNewCustomerDialog}
                    />
                </DrawerContent>
            </Drawer>
        );
    }

    if (!isValidIp) {
        return null;
    }

    return (
        <div className="digital-stamps-client">
            <div className="digital-stamps-client--header">
                <img alt="Alexela logo" src={logo} />
            </div>
            <Grid className="digital-stamps-client--content">
                <GridCell span={12}>
                    {renderStationSelection()}
                </GridCell>
                <GridCell span={12}>
                    {renderBonuscardNumberBinding()}
                </GridCell>
                <GridCell span={12} ref={alertRef}>
                    {renderPersonalCodeSearch()}
                </GridCell>
                <GridCell span={12}>
                    {renderAlertMessage()}
                </GridCell>
                {(stampsSummary.fetching || postAddCustomer.fetching || convertProduct.fetching || postBindBonuscard.fetching || redeemCampaignPrize.fetching) &&
                    <GridCell span={12}>
                        <Loader type={Loader.TYPE_CENTER} />
                    </GridCell>
                }
                {stampsSummary.fetched &&
                    <>
                        <GridCell span={12}>
                            {renderCustomerInfo()}
                        </GridCell>
                        <GridCell span={12} className="digital-stamps-client--separator" />
                        {campaignPrizes.fetched &&
                            <GridCell span={12}>
                                {renderCampaignPrizeSelection()}
                            </GridCell>
                        }
                        <GridCell span={12}>
                            {renderProductSelection()}
                        </GridCell>
                        <GridCell span={12}>
                            <DigitalStampsConverter
                                personalCode={personalCode}
                                isClient
                            />
                        </GridCell>
                        <GridCell span={12}>
                            {renderHistory()}
                        </GridCell>
                    </>
                }
            </Grid>

            {renderNewCustomerDrawer()}
        </div>
    )
};

export default DigitalStampsClient;