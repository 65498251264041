import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";

import { ListItem } from "@rmwc/list";
import { Button } from "@rmwc/button";
import Menu, { MenuList } from "@material/react-menu";
import { Corner } from "@material/react-menu-surface";

import RoleDialog from "../LoginForm/RoleDialog";
import auth from "../../auth/authenticate";
import { fetchCanSetAuthorizations } from "../../actions/accountActions";
import { logout } from "../../actions/authActions";
import { SettingsPath, TranslationSettingsPath, AuthorizationsPath, StockPriceAlertsPath, UserManagementSettingsPath, MarketingNotificationsPath, DebugPath, HintHistoryPath } from "../../const/routes";
import { setSecondaryMenuItemByPath } from "../../helpers/breadCrumbsHelper";
import { getMenu } from "../../const/MenuDesktop";
import {IsLatvianWeb} from "../../helpers/AlternativeDesign";

class MenuProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            openRolesDialog: false,
            isAdmin: auth.isAdmin(),
            isAgent: auth.isAgent(),
            canChangeAuthorizations: false,
            menu: getMenu() || [],
            isLatvianWeb: IsLatvianWeb() || false
        };
        this.closeRolesDialog = this.closeRolesDialog.bind(this);
        this.openRolesDialog = this.openRolesDialog.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        if (this.props.isLoggedIn && auth.isLoggedIn() && !this.state.isAdmin && !this.state.isAgent) {
            this.props.fetchCanSetAuthorizations()
                .then(x => {
                    this.setState({
                        canChangeAuthorizations: x.value.data
                    })
                })
                .catch(x => {
                })
        }
    }

    closeRolesDialog() {
        this.setState({
            openRolesDialog: false
        });
    }

    openRolesDialog() {
        this.setState({
            openRolesDialog: true,
            openMenu: false
        });
    }

    openMenu() {
        this.setState({
            openMenu: true
        });
    }

    toggleMenu() {
        this.setState({
            openMenu: !this.state.openMenu
        });
    }

    closeMenu() {
        this.setState({
            openMenu: false
        });
    }

    logout() {
        auth.startLogout();
    }

    getMenuOffset() {
        const anchor = this.refs.anchor;
        if (!anchor) return { top: 0, left: 0 };
        const rect = anchor.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX + anchor.offsetWidth,
            top: rect.top + window.scrollY + anchor.offsetHeight
        };
    }

    onClickMenuLink = (path) => {
        this.props.setSecondaryMenuItemByPath(this.state.menu, path);
    }

    renderMenuDropdown() {
        return (
            <Menu
                open={this.state.openMenu}
                anchorElement={this.refs.anchor}
                anchorCorner={Corner.BOTTOM_START}
                anchorMargin={this.getMenuOffset()}
                className="mdc-menu--profile">
                <MenuList>
                    {(!this.state.isLatvianWeb || this.state.isAdmin) &&
                        <Button
                            className="mdc-button mdc-button--text mdc-button--link"
                            onClick={this.openRolesDialog}>
                            <ListItem>
                                <FormattedMessage id="Header.RoleSelection"/>
                            </ListItem>
                        </Button>
                    }
                    <Link to={SettingsPath} onClick={() => this.onClickMenuLink(SettingsPath)}>
                        <ListItem>
                            <FormattedMessage id="Menu.Settings" />
                        </ListItem>
                    </Link>
                    {this.state.isAdmin &&
                        <>
                            <Link to={TranslationSettingsPath} onClick={() => this.onClickMenuLink(TranslationSettingsPath)}>
                                <ListItem>
                                    <FormattedMessage id="Menu.TranslationSettings" />
                                </ListItem>
                            </Link>
                            <Link to={StockPriceAlertsPath} onClick={() => this.onClickMenuLink(StockPriceAlertsPath)}>
                                <ListItem>
                                    <FormattedMessage id="Menu.StockPriceAlerts" />
                                </ListItem>
                            </Link>
                            <Link to={UserManagementSettingsPath} onClick={() => this.onClickMenuLink(UserManagementSettingsPath)}>
                                <ListItem>
                                    <FormattedMessage id="Menu.UserManagement" />
                                </ListItem>
                            </Link>
                            <Link to={MarketingNotificationsPath} onClick={() => this.onClickMenuLink(MarketingNotificationsPath)}>
                                <ListItem>
                                    <FormattedMessage id="Menu.MarketingNotifications" />
                                </ListItem>
                            </Link>
                            <Link to={HintHistoryPath} onClick={() => this.onClickMenuLink(HintHistoryPath)}>
                                <ListItem>
                                    <FormattedMessage id="Menu.HintHistory" />
                                </ListItem>
                            </Link>
                            <Link to={DebugPath} onClick={() => this.onClickMenuLink(DebugPath)}>
                                <ListItem>Debug</ListItem>
                            </Link>
                        </>
                    }
                    {(this.state.isAdmin || this.state.canChangeAuthorizations) &&
                        <Link to={AuthorizationsPath} onClick={() => this.onClickMenuLink(AuthorizationsPath)}>
                            <ListItem>
                                <FormattedMessage id="Menu.Authorizations" />
                            </ListItem>
                        </Link>
                    }
                    <Button
                        className="mdc-button mdc-button--text mdc-button--link mdc-button--link-logout"
                        onClick={this.logout}
                    >
                        <ListItem>
                            <FormattedMessage id="Header.LogOut" />
                            <i className="icon-arrow-right" />
                        </ListItem>
                    </Button>
                </MenuList>
            </Menu>);
    }

    renderRoleButton() {
        let roleName = auth.getRole();

        let profileIcon = {
            icon: "user-mobile",
            strategy: "className",
            basename: "icon",
            prefix: "icon-"
        };

        let trailingIcon = {
            icon: "angle-down",
            strategy: "className",
            basename: "icon",
            prefix: "icon-"
        };

        if (this.state.openMenu) {
            trailingIcon.className = "rotate";
        }

        return (
            <Button
                onClick={this.toggleMenu}
                className="mdc-button--text nav__link nav__link--profile"
                trailingIcon={trailingIcon}
                icon={profileIcon}
            >
                {roleName ? roleName.title : null}
            </Button>
        );
    }

    render() {
        return (
            <>{
                this.props.isLoggedIn && auth.isLoggedIn() &&
                <>
                    <RoleDialog
                        key={"roleDialog" + this.state.openRolesDialog}
                        isOpen={this.state.openRolesDialog}
                        onClose={this.closeRolesDialog}
                    />
                    <div
                        onMouseEnter={this.openMenu}
                        onMouseLeave={this.closeMenu}
                        className="mdc-menu-surface--anchor"
                        ref="anchor">
                        {this.renderRoleButton()}
                        {this.renderMenuDropdown()}
                    </div>
                </>
            }</>
        );
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ logout, fetchCanSetAuthorizations, setSecondaryMenuItemByPath }, dispatch);
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.auth.isLoggedIn
    };
}

export default injectIntl(
    withRouter(connect(mapStateToProps, matchDispatchToProps)(MenuProfile))
);

