import React from "react";
import { AccountNumberLength, getBankByAccountNumber, getBankIconByIBAN } from '../../helpers/AccountNumberHelper';
import BaseFormComponent from "../common/BaseFormComponent";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Typography } from "@rmwc/typography";
import { Dialog, DialogActions, DialogButton, DialogContent } from "@rmwc/dialog";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from '@rmwc/button';
import moment from "moment/moment";
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import SimpleReactValidator from "simple-react-validator";
import RequiredIfValidatorRule from "../../validators/RequiredIfValidatorRule";
import Loader from '../common/Loader';
import {
    fetchBanks,
    prepareElmoContractOfferSinging,
    finalizeElmoContractOfferSinging,
    postFinalizeContract,
    downloadElmoContractPdf
} from '../../actions/contractActions';
import { renderFormatMobile } from "../../helpers/RenderHelper";
import { ContractThankYouPath, AgentContractThankYouPath, StartPath } from "../../const/routes";
import classnames from "classnames";
import { SendActionEvent } from "../../helpers/GoogleTagHelper";
import auth from "../../auth/authenticate";
import IdCodeValidatorRule from "../../validators/IdCodeValidatorRule";
import { Gas, Electricity } from "../../const/contractType";
import {IsLatvianWeb} from "../../helpers/AlternativeDesign";
import { Icon } from "@rmwc/icon";
import { ContractDigitalSign, ContractDigitalSignMinimal, ContractTerms, ContractTermsCheckbox } from "../../components/Contract";
import ContractTOS from "./ContractTOS";
import LoadingCircle from "../common/LoadingCircle";
import { trackEvent } from "../../appInsights";
import { fetchMainContact } from "../../actions/accountActions";
import { postActionLog } from "../../actions/actionLogActions";
import { AuthenticationType } from "../../const/authenticationType";
import AlertMessage from "../common/AlertMessage";

import rohesartsLogoMixed from "../../images/rohesarts-logo-mixed.svg";

import "../../styles/blocks/_info-list.scss";
import "../../styles/blocks/_contract.scss";
import ProductType from "../../const/productType";
import { ActionLogType } from "../../const/actionLogType";

const ELMO_TIMEOUT_SECONDS = 65;

class ContractSign extends BaseFormComponent {
    static fieldSelectedBank = "SelectedBank";
    static fieldAccountNr = "AccountNr";
    static fieldSSN = "SSN";
    static fieldName = "Name";
    static fieldTOS = "TOS";
    static fieldPenaltyTOS = "PenaltyTOS";

    constructor(props) {
        super(props);

        this.cancelSource = null;
        this.uiErrorsRef = React.createRef();
        this.state = {
            showError: true,
            wishEInvoiceToBank: false,
            [ContractSign.fieldSelectedBank]: null,
            [ContractSign.fieldAccountNr]: props.contract.billingAccount || '',
            [ContractSign.fieldSSN]: this.props.contract.ssn || '',
            [ContractSign.fieldName]: `${this.props.contract.firstName} ${this.props.contract.lastName}`,
            [ContractSign.fieldTOS]: false,
            [ContractSign.fieldPenaltyTOS]: false,
            sendInvoiceToPostalAddress: auth.isAgent() && auth.getRoleHasNoEmail(),
            isAgent: auth.isAgent(),
            selectedBankLogo: null,
            userCrmId: auth.getUserId(),
            errorText: null,
            isPenaltyProduct: this.props.selectedProduct.fineAmount > 0,
            isUniversalProduct: this.props.selectedProduct.type === ProductType.UniversalPrice,
            isAET: this.props.selectedProduct.company.toUpperCase() === "AET",
            isUserSignWarningModalOpen: false,
            isLatvianWeb: IsLatvianWeb(),
            isTOSChecked: false
        };

        this.validator = new SimpleReactValidator({
            validators: { ...RequiredIfValidatorRule.rule(), ...IdCodeValidatorRule.rule() },
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });

        this.onAccountNumberFieldChange = this.onAccountNumberFieldChange.bind(this);
        this.downloadContractPdf = this.downloadContractPdf.bind(this);
        this.onTOSCheckedChanged = this.onTOSCheckedChanged.bind(this);
        this.onPenaltyTOSCheckedChanged = this.onPenaltyTOSCheckedChanged.bind(this);
        this.areTOSBoxesChecked = this.areTOSBoxesChecked.bind(this);
        this.getFileToSign = this.getFileToSign.bind(this);
        this.onDocumentSigned = this.onDocumentSigned.bind(this);
        this.addActionLog = this.addActionLog.bind(this);
        this.renderContractFinalizeComponent = this.renderContractFinalizeComponent.bind(this);
    }

    componentDidMount() {
        if (!this.props.banksFetched && !this.props.banksFetching) {
            this.props.fetchBanks();
        }

        if (this.state.isAgent) {
            this.props.fetchMainContact();
        }
    }

    getFileToSign() {
        return async dispatch => {
            const { value } = await dispatch(prepareElmoContractOfferSinging({
                contractId: this.props.contract?.contractId,
                contractNumber: this.props.contract?.contractNumber,
                contractType: this.props.contractType
            }));

            return value.data;
        }
    }

    onDocumentSigned(agentContractAction) {
        return async _ => {
            this.submitAgentContract(agentContractAction);
        }
    }

    areTOSBoxesChecked() {
        if (this.state.isPenaltyProduct) {
            return !!this.state[ContractSign.fieldTOS] && !!this.state[ContractSign.fieldPenaltyTOS];
        }

        return !!this.state[ContractSign.fieldTOS];
    }

    submitAgentContract(agentContractAction) {
        if (agentContractAction === "signed" && !this.areTOSBoxesChecked()) {
            return false;
        }

        const trackedEventName = this.props.contractType === Electricity
            ? "electricitySubmitAgentContract"
            : "gasSubmitAgentContract";

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.setState({ showErrors: true }, this.scrollToRef.bind(this, this.uiErrorsRef));

            trackEvent(trackedEventName, {
                crmId: this.state.userCrmId,
                event: "validation failed",
                action: agentContractAction
            });

            return;
        }

        let data = {}
        if (this.state.wishEInvoiceToBank) {
            data = {
                billingBank: this.state[ContractSign.fieldSelectedBank].bic,
                billingAccount: this.state[ContractSign.fieldAccountNr],
                billingSSN: this.state[ContractSign.fieldSSN],
                billingName: this.state[ContractSign.fieldName]
            }
        }
        data.contractType = this.props.contractType;
        data.agentContractAction = agentContractAction;
        data.sendInvoiceToPostalAddress = this.state.sendInvoiceToPostalAddress;
        if (this.state.sendInvoiceToPostalAddress) {
            data.billingAddress = this.props.contract.billingAddress;
        }

        trackEvent(trackedEventName, {
            crmId: this.state.userCrmId,
            event: "pre finalize click",
            action: agentContractAction,
            data: data
        });

        this.props.postFinalizeContract(data)
            .then(() => {
                trackEvent(trackedEventName, {
                    crmId: this.state.userCrmId,
                    event: "contract finalize successful",
                    action: agentContractAction
                });

                if (agentContractAction === "signed") {
                    SendActionEvent(this.props.contractType === Electricity ? "ElectricityFinalized" : "GasFinalized");
                }

                this.addActionLog(ActionLogType.ContractFinalized, {
                    agentContractAction: agentContractAction,
                    eventName: trackedEventName
                });

                if (agentContractAction === "cancel") {
                    this.props.history.push(StartPath);
                } else if (!this.state.isAgent) {
                    this.props.history.push(ContractThankYouPath);
                } else {
                    this.props.history.push(AgentContractThankYouPath);
                }
            })
            .catch(err => {
                trackEvent(trackedEventName, {
                    crmId: this.state.userCrmId,
                    event: "contract finalize failed",
                    action: agentContractAction,
                    error: {
                        code: err.code,
                        message: err.message,
                        response: err.response?.data
                    }
                });
            });
    }

    downloadContractPdf() {
        const { contractId, contractNumber } = this.props.contract;
        this.props.downloadElmoContractPdf(contractId, contractNumber);
    }

    renderInfo() {
        const contract = this.props.contract;

        return (
            <>
                <GridCell span={12}>
                    {this.props.finalizeError && this.errors(this.props.finalizeError, "Contract.Error.Create")}
                    {this.state.showErrors && this.errorsFromObject(this.validator.getErrorMessages(), this.uiErrorsRef)}
                    <Typography
                        use="headline1"
                    >
                        <FormattedMessage id="Contract.Confirm.Title" />
                    </Typography>
                </GridCell>
                <GridCell span={4}>
                    <Typography use="headline4" className="mb-15">
                        <FormattedMessage id="Contract.Confirm.Data" />
                    </Typography>
                    <Typography use="body1" className="contract-contact-info">
                        {contract.firstName} {contract.lastName}
                    </Typography>
                    <Typography use="body1" className="contract-contact-info">
                        {contract.email}
                    </Typography>
                    <Typography use="body1" className="contract-contact-info">
                        {renderFormatMobile(contract.mobile)}
                    </Typography>
                    <Typography use="body1" className="contract-contact-info">
                        {contract.ssn}
                    </Typography>
                </GridCell>
                <GridCell mobile={4} tablet={4} desktop={8}>
                    <Typography use="headline4" className="mb-15">
                        <FormattedMessage id="Contract.Product.Title" />
                    </Typography>
                    <Typography use="body1" className="contract-product-title">
                        {contract.products[0].name}
                    </Typography>
                    {contract.consumptionLocations.map((item) =>
                        <Typography key={item.id} use="body1" className="contract-contact-info">
                            <FormattedMessage id="Contract.Location.Item"
                                values={{
                                    eic: item.eicCode,
                                    address: item.address || "-"
                                }}
                            />
                        </Typography>
                    )}
                    <Typography use="body1" className="contract-contact-info">
                        <FormattedMessage id="Contract.StartDate"
                            values={{
                                date: moment(contract.products[0].start).format("DD.MM.YYYY")
                            }}
                        />
                    </Typography>
                    {!this.props.finalizeFetching &&
                        <span className="information-icon" onClick={this.props.goBack}>
                            <FormattedMessage id="Contract.Product.Change" />
                        </span>
                    }
                </GridCell>

                {contract.isGreen &&
                    <GridCell span={4}>
                        <Typography use="headline4" className="mb-15">
                            <FormattedMessage id="Contract.Step2.AdditionalServices.Title" />
                        </Typography>

                        <div className="contract-rohesarts-logo">
                            <img alt="Rohesarts logo" src={rohesartsLogoMixed} />
                        </div>
                    </GridCell>
                }
            </>
        )
    }

    toggleFilter(key) {
        this.validator.purgeFields();
        this.setState({
            [key]: !this.state[key]
        });
    }

    onAccountNumberFieldChange(e) {
        e.target.value = e.target.value.toUpperCase();
        this.onChange(e);

        const bank = getBankByAccountNumber(this.props.banks, e.target.value);
        if (e.target.value.length === AccountNumberLength && bank) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    [ContractSign.fieldSelectedBank]: bank
                }
            });
            this.selectedBank = getBankIconByIBAN(bank.ibanId);
        } else {
            this.selectedBank = null;
        }
    }

    onUserSignButtonClicked(hasNoEmail) {
        if (hasNoEmail) {
            this.setState({ isUserSignWarningModalOpen: true });
            return;
        }

        this.submitAgentContract("usersign");
    }

    buildFullAddress(address) {
        if (!address) { return ""; }

        let fullAddress = address.street;
        fullAddress += !address.houseNo ? "" : " " + address.houseNo;
        fullAddress += !address.apartmentNo ? ", " : "-" + address.apartmentNo + ", ";
        fullAddress += !address.parish ? "" : address.parish + ", ";
        fullAddress += address.city + ", ";
        fullAddress += address.county + ", ";
        fullAddress += address.code;

        return fullAddress;
    }

    renderInvoiceAddress(contract) {
        return this.state.sendInvoiceToPostalAddress
            ?
            <FormattedMessage id="Contract.InvoiceOptions.InvoiceToPostalAddressPrefix"
                values={{
                    address: this.buildFullAddress(contract.billingAddress)
                }} />
            :
            <FormattedMessage id="Contract.InvoiceOptions.EmailInfoPrefix"
                values={{
                    email: contract.email
                }} />;
    }

    renderInvoiceOptions() {
        const hasInvoiceChannel = this.state.sendInvoiceToPostalAddress || !!this.props.contract.email;

        return (
            <GridCell span={12}>
                <Typography use="headline2" className="mt-30 mb-15">
                    <FormattedMessage id="Contract.InvoiceOptions" />
                </Typography>
                <Typography use="body1" className="mb-15">
                    {this.renderInvoiceAddress(this.props.contract)}
                </Typography>
                {this.state.isAgent &&
                    !hasInvoiceChannel &&
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id="Contract.InvoiceOptions.NoInvoiceOptionsError" />} />
                }
                {!this.state.sendInvoiceToPostalAddress && !this.state.isLatvianWeb &&
                    <div className={classnames("mdc-toggle-container", {
                        "link-disabled": !!this.props.finalizeFetching
                    })}>
                        <div
                            className={classnames("mdc-button-toggle", {
                                "active": this.state.wishEInvoiceToBank
                            })}
                            onClick={this.toggleFilter.bind(this, "wishEInvoiceToBank")}>
                        </div>
                        <label onClick={this.toggleFilter.bind(this, "wishEInvoiceToBank")}>
                            <FormattedMessage id="Contract.InvoiceOptions.WishEInvoiceToBank" />
                        </label>
                    </div>}

                {this.state.wishEInvoiceToBank && !this.state.isLatvianWeb &&
                    <>
                        <div className="field--colored field--columns">
                            <div className="field--column">
                                <TextFieldGroup
                                    validator={this.validator}
                                    rules={[`requiredIf:${this.state.wishEInvoiceToBank}`, { regex: "^[A-Z]{2}[0-9]{2}(10|22|42|77|96|17)[0-9]{14}$" }]}
                                    className="field"
                                    field={ContractSign.fieldAccountNr}
                                    value={this.state[ContractSign.fieldAccountNr]}
                                    onChange={this.onAccountNumberFieldChange}
                                    label="Contract.InvoiceOptions.AccountNr"
                                />
                            </div>
                            <div className="field--column contract-bank-logo">
                                {this.selectedBank &&
                                    <Icon icon={{
                                        icon: this.selectedBank,
                                        strategy: "className",
                                        basename: "icon",
                                        prefix: "icon-"
                                    }}
                                    />
                                }
                            </div>
                        </div>

                        <div className="field--colored field--columns">
                            <div className="field--column">
                                <TextFieldGroup
                                    field={ContractSign.fieldSSN}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    rules={`requiredIf:${this.state.wishEInvoiceToBank}| idCode: EE`}
                                    value={this.state[ContractSign.fieldSSN]}
                                    type="number"
                                    label="Contract.InvoiceOptions.SSN"
                                />
                            </div>
                            <div className="field--column">
                                <TextFieldGroup
                                    validator={this.validator}
                                    rules={`requiredIf:${this.state.wishEInvoiceToBank}`}
                                    className="field"
                                    field={ContractSign.fieldName}
                                    value={this.state[ContractSign.fieldName]}
                                    onChange={this.onChange}
                                    label="Contract.InvoiceOptions.Name"
                                />
                            </div>
                        </div>
                    </>
                }
            </GridCell>
        );
    }

    renderContractFiles() {
        if (this.state.isLatvianWeb) {
            return null;
        }

        return (
            <>
                <GridCell span={8}>
                    <Typography use="headline2" className="mb-15 mt-30">
                        <FormattedMessage id="Contract.Signing.Title" />
                    </Typography>
                    <Typography use="body1">
                        <FormattedMessage id="Contract.Signing.Content" />
                    </Typography>
                </GridCell>
                <GridCell span={12}>
                    <ul className="info-list">
                        {this.props.contract.authorizationUrl !== null &&
                            this.renderContractsList(this.props.contract.contractNumber, this.props.contract.authorizationUrl, false)}
                        {this.renderContractsList(this.props.contract.contractNumber, this.props.contract.contractUrl, true)}
                    </ul>
                </GridCell>
            </>
        );
    }

    renderContractsList(contractNumber, fileUrl, isContract) {
        return (
            <li className="info-list__item">
                <div className="col">
                    <div className="title mdc-theme--primary">
                        <i className="icon icon-list" />
                        <FormattedMessage
                            id={isContract ? "Contract.Signing.Contract" : "Contract.Signing.Authorization"}
                            values={{
                                contractNr: contractNumber
                            }}
                        />
                    </div>
                    <div className="wrap">
                        {isContract
                            ? <Button onClick={this.downloadContractPdf} className="mdc-button--link">
                                <FormattedMessage id="Contract.Signing.Download" />
                            </Button>
                            : <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                <FormattedMessage id="Contract.Signing.Download" />
                            </a>}
                    </div>
                </div>
            </li>
        )
    }

    renderUserSignWarningDialog() {
        return (
            <Dialog
                open={this.state.isUserSignWarningModalOpen}
                onClose={() => this.setState({ isUserSignWarningModalOpen: false })}
            >
                <DialogContent>
                    <Typography use="headline4" className="mdc-typography mdc-theme--primary mb-40">
                        <FormattedMessage id="General.Attention" />
                    </Typography>
                    <div className="mb-10">
                        <AlertMessage
                            type={AlertMessage.TYPE_WARN}
                            title={<FormattedMessage id="Contract.Sign.Agent.Action.UserSign.Warning" />}
                            isSmall
                        />
                    </div>
                </DialogContent>
                <DialogActions className="mdc-dialog mdc-dialog__content">
                    <DialogButton action="close" className="mdc-button mdc-button--unelevated">
                        <FormattedMessage id="General.Close" />
                    </DialogButton>
                </DialogActions>
            </Dialog>
        );
    }

    renderAgentActions() {
        const hasPhone = this.state.isAgent && this.props.mainContact?.mobile;
        const hasPersonalCode = this.state.isAgent && this.props.mainContact?.idCode;
        const hasInvoiceChannel = this.state.sendInvoiceToPostalAddress || !!this.props.contract.email;
        const canSign = !this.props.finalizeFetching && hasInvoiceChannel;
        const hasNoEmail = auth.getRoleHasNoEmail();

        return (
            <>
                <GridCell span={12}>
                    <Typography use="headline2" className="mb-15 mt-30">
                        <FormattedMessage id="Contract.Agent.Title" />
                    </Typography>
                    <Typography use="body1" className="mb-15">
                        <FormattedMessage id="Contract.Agent.Content" />
                    </Typography>
                    {this.props.contractType !== Gas &&
                        <Button
                            unelevated
                            type="button"
                            disabled={!canSign}
                            className="mb-15 mr-10"
                            onClick={this.submitAgentContract.bind(this, "phone")}
                        >
                            {this.props.finalizeFetching ?
                                <Loader type={Loader.TYPE_BUTTON} /> :
                                <FormattedMessage id="Contract.Agent.Action.Phone" />
                            }
                        </Button>
                    }
                    <Button
                        unelevated
                        type="button"
                        disabled={!canSign}
                        className="mb-15 mr-10"
                        onClick={this.submitAgentContract.bind(this, "paper")}
                    >
                        {this.props.finalizeFetching ?
                            <Loader type={Loader.TYPE_BUTTON} /> :
                            <FormattedMessage id="Contract.Agent.Action.SignedOnPaper" />
                        }
                    </Button>
                    <Button
                        unelevated
                        type="button"
                        disabled={!canSign}
                        className="mb-15 mr-10"
                        onClick={() => this.onUserSignButtonClicked(hasNoEmail)}
                    >
                        {this.props.finalizeFetching ?
                            <Loader type={Loader.TYPE_BUTTON} /> :
                            <FormattedMessage id="Contract.Agent.Action.UserSign" />
                        }
                    </Button>
                    <ContractDigitalSignMinimal
                        title="Contract.Agent.Action.SmartId"
                        className="mb-15 mr-10"
                        dataForSigning={{
                            signingMethod: AuthenticationType.SmartId,
                            personalCodeForSigning: this.props.mainContact?.idCode
                        }}
                        disabled={!canSign || !hasPersonalCode}
                        getFileToSign={this.getFileToSign}
                        setError={(err) => this.setState({ errorText: err })}
                        onDocumentSigned={() => this.onDocumentSigned("smartId")}
                    />
                    <ContractDigitalSignMinimal
                        title="Contract.Agent.Action.MobileId"
                        className="mb-15 mr-10"
                        dataForSigning={{
                            signingMethod: AuthenticationType.MobileId,
                            personalCodeForSigning: this.props.mainContact?.idCode,
                            mobileNumber: this.props.mainContact?.mobile
                        }}
                        disabled={!canSign || !hasPersonalCode || !hasPhone}
                        getFileToSign={this.getFileToSign}
                        setError={(err) => this.setState({ errorText: err })}
                        onDocumentSigned={() => this.onDocumentSigned("mobileId")}
                    />
                    <Button
                        unelevated
                        type="button"
                        disabled={!!this.props.finalizeFetching || !this.state.personalCode}
                        className="mb-15 mr-10 mdc-button--red"
                        onClick={this.submitAgentContract.bind(this, "cancel")}
                    >
                        {this.props.finalizeFetching ?
                            <Loader type={Loader.TYPE_BUTTON} /> :
                            <FormattedMessage id="Contract.Agent.Action.Cancel" />
                        }
                    </Button>
                </GridCell>
                {this.state.errorText &&
                    <>
                        <GridCell span={11}>
                            <AlertMessage
                                isSmall
                                type={AlertMessage.TYPE_ALERT}
                                title={this.state.errorText}
                            />
                        </GridCell>
                        <GridCell span={1} align="middle">
                            <Button
                                onClick={() => this.setState({ errorText: null })}
                                icon={{
                                    icon: "close",
                                    strategy: "className",
                                    basename: "icon",
                                    prefix: "icon-",
                                    className: "digital-sign-minimal-btn--icon"
                                }}
                            />
                        </GridCell>
                    </>
                }
            </>
        );
    }

    addActionLog(action, extraProps) {
        const contract = this.props.contract;

        this.props.postActionLog({
            action: action,
            additionalData: JSON.stringify({
                contractId: contract.contractId,
                contractNumber: contract.contractNumber,
                productCode: contract.products[0].code,
                productName: contract.products[0].name,
                productId: contract.products[0].id,
                ...extraProps
            })
        });
    }

    onTOSCheckedChanged(event) {
        const isChecked = event.target.checked;

        this.setState({
            [ContractSign.fieldTOS]: isChecked
        });

        const trackedEventName = this.props.contractType === Electricity
            ? "electricityTOSCheckedChanged"
            : "gasTOSCheckedChanged";

        trackEvent(trackedEventName, {
            userCrmId: this.state.userCrmId,
            checked: isChecked
        });

        this.addActionLog(ActionLogType.ConfirmCheckboxChecked, {trackedEventName, isChecked});
    }

    onPenaltyTOSCheckedChanged(event) {
        const isChecked = event.target.checked;

        this.setState({
            [ContractSign.fieldPenaltyTOS]: isChecked
        });

        const trackedEventName = this.props.contractType === Electricity
            ? "electricityPenaltyProductTOSCheckedChanged"
            : "gasPenaltyProductTOSCheckedChanged";

        trackEvent(trackedEventName, {
            userCrmId: this.state.userCrmId,
            checked: isChecked
        });

        this.addActionLog(ActionLogType.ConfirmCheckboxChecked, {trackedEventName, isChecked});
    }

    renderFinishButton() {
        const loadingMessages = [
            {
                displayTime: 15,
                message: this.props.intl.formatMessage({ id: "Contract.Electricity.Loading.Elering" })
            },
            {
                displayTime: 45,
                message: this.props.intl.formatMessage({ id: "Contract.Electricity.Loading.Elering.Second" })
            },
            {
                displayTime: 65,
                message: this.props.intl.formatMessage({ id: "Contract.Electricity.Loading.Elering.Third" })
            }
        ];

        return (
            <GridCell span={12}>
                {this.props.finalizeFetching
                    ?
                    <LoadingCircle
                        timeRemainingSeconds={ELMO_TIMEOUT_SECONDS}
                        messages={loadingMessages}
                    />
                    :
                    <Button
                        unelevated
                        type="button"
                        disabled={!!this.props.finalizeFetching || !this.areTOSBoxesChecked()}
                        className="mb-15 mr-10 right"
                        onClick={this.submitAgentContract.bind(this, "signed")}
                    >
                        {this.props.finalizeFetching ?
                            <Loader type={Loader.TYPE_BUTTON} /> :
                            <FormattedMessage id="Contract.Action.UserSign" />
                        }
                    </Button>
                }
            </GridCell>
        );
    }

    onPrepareSigning = () => async dispatch => {
        try {
            const contract = this.props.contract;
            const contractData = {
                contractId: contract.contractId,
                contractNumber: contract.contractNumber,
                contractType: Gas
            }

            const { value } = await dispatch(prepareElmoContractOfferSinging({
                ...contractData,
                signingDeadline: null
            }));

            return {
                ...value.data,
                contractData: {...contractData}
            };
        } catch {
            this.setState({ errorText: "Contract.Gas.Signing.Error" });
            return null;
        }
    }

    onContractFinalized = (data) => async (dispatch) => {
        const contract = this.props.contract;

        await dispatch(finalizeElmoContractOfferSinging({
            contractId: contract.contractId,
            contractNumber: contract.contractNumber,
            contractType: Gas,
            signingMethod: data.signingMethod
        }));

        this.props.history.push(ContractThankYouPath);
    }

    renderContractFinalizeComponent() {
        if (this.state.isLatvianWeb) {
            return (
                <ContractDigitalSign
                    validator={this.validator}
                    onDownloadPreview={() => this.downloadContractPdf()}
                    getFileToSign={this.onPrepareSigning}
                    onDocumentSigned={this.onContractFinalized}
                    disabled={!this.state.isTOSChecked}
                >
                    <ContractTermsCheckbox
                        checked={this.state.isTOSChecked}
                        onSetIsTermsAcceptedChanged={() => this.setState(prev => ({ isTOSChecked: !prev.isTOSChecked}))}
                        titleKey="Contract.ElectricityOffer.AgreeTerms"
                        termsValues={[
                            {
                                termsKey: "TOSLink",
                                termsLinkKey: "Contract.ElectricityOffer.TOSLink.Terms",
                                termsLinkTextKey: "Contract.ElectricityOffer.TOSLink.TermsText"
                            },
                            {
                                termsKey: "priceLink",
                                termsLinkKey: "Contract.ElectricityOffer.TOSLink.PriceList",
                                termsLinkTextKey: "Contract.ElectricityOffer.TOSLink.Text",
                            }
                        ]}
                    />
                </ContractDigitalSign>
            );
        } else {
            const { selectedProduct } = this.props;
            const { isPenaltyProduct, isUniversalProduct, isAET } = this.state;

            return (
                <>
                    {isPenaltyProduct &&
                        <>
                            <ContractTerms termsTitleId="ContractProductChange.PenaltyProduct.TermsTitle" termsTextId="ContractProductChange.PenaltyProduct.TermsText" />
                            <GridCell span={12}>
                                <div className="mdc-form-field">
                                    <ContractTermsCheckbox
                                        checked={this.state[ContractSign.fieldPenaltyTOS]}
                                        onSetIsTermsAcceptedChanged={this.onPenaltyTOSCheckedChanged}
                                        titleKey="Contract.AcceptTerms.PenaltyProduct"
                                        disabled={!!this.props.finalizeFetching}
                                    />
                                </div>
                            </GridCell>
                        </>
                    }

                    {isUniversalProduct &&
                        <>
                            {isAET
                                ? <ContractTerms termsTitleId="Contract.UniversalProduct.TermsTitle.AET" termsTextId="Contract.UniversalProduct.TermsText.AET" />
                                : <ContractTerms termsTitleId="Contract.UniversalProduct.TermsTitle" termsTextId="Contract.UniversalProduct.TermsText" />
                            }
                        </>
                    }

                    {!this.state.isAgent &&
                        <GridCell span={12}>
                            <ContractTOS fieldName={ContractSign.fieldTOS}
                                isChecked={this.state[ContractSign.fieldTOS]}
                                onChange={this.onTOSCheckedChanged}
                                contractType={this.props.contractType}
                                productType={selectedProduct.type}
                                isGreen={this.props.contract.isGreen}
                                disabled={!!this.props.finalizeFetching}
                            />
                        </GridCell>
                    }

                    {!this.state.isAgent && this.renderFinishButton()}
                </>
            );
        }
    }

    render() {
        return (
            <Grid>
                {this.renderInfo()}
                {this.renderInvoiceOptions()}
                {this.renderContractFiles()}
                {this.state.isAgent && this.renderAgentActions()}

                {this.renderContractFinalizeComponent()}

                {!this.props.finalizeFetching &&
                    <GridCell span={12}>
                        <span className="information-icon" onClick={this.props.goBack}>
                            <FormattedMessage id="Contract.Product.Change" />
                        </span>
                    </GridCell>
                }
                {this.renderUserSignWarningDialog()}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        mainContact: state.mainContact.data,
        mainContactFetched: state.mainContact.fetched,
        contract: state.putContract.data || { products: [{}], consumptionLocations: [] },
        banksFetching: state.banks.fetching,
        banksFetched: state.banks.fetched,
        banks: state.banks.data,
        finalizeFetching: state.postFinalizeContract.fetching,
        finalizeError: state.postFinalizeContract.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchBanks,
        fetchMainContact,
        postFinalizeContract,
        downloadElmoContractPdf,
        postActionLog
    }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(ContractSign)));
