import {
    InvoicesPath,
    ContractsPath,
    CommunityPath,
    FuelTaxiOrderPath,
    ServicePath,
    ConsumptionPath,
    ProductionPath,
    NordpoolPath,
    DigitalStampsPath,
    PaymentsPath,
    StatisticsPath,
    CardsPath,
    BasketballCommunityPath,
    SummerCampaignPath
} from "./routes";

import {alexelaUrl, alexelaUrlLv} from "./urls";
import { LotteryCampaign } from "./featureFlags";

export const mobileMenuHierarchy = [
    {
        path: ServicePath,
        name: 'Menu.Service',
        children: [
            {
                path: InvoicesPath,
                name: 'Menu.Invoices'
            },
            {
                path: ContractsPath,
                name: 'Menu.Contracts'
            },
            {
                path: CardsPath,
                name: 'Menu.Cards'
            },
            {
                path: NordpoolPath,
                name: 'Menu.Nordpool'
            },
            {
                path: FuelTaxiOrderPath,
                name: 'Menu.FuelTaxiOrderForm'
            }
        ]
    },
    {
        path: ConsumptionPath,
        name: 'Menu.Consumption'
    },
    {
        path: ProductionPath,
        name: 'Menu.Production'
    },
    {
        path: CommunityPath,
        name: 'Menu.Community',
        children: [
            {
                path: CommunityPath,
                name: "Menu.RefuelAndPlantTrees"
            },
            {
                path: BasketballCommunityPath,
                name: "Menu.BasketballCommunity"
            }
        ]
    },
    {
        path: DigitalStampsPath,
        name: "Menu.DigitalStamps"
    },
    {
        path: alexelaUrl,
        name: 'TopContent.Navigation.Web'
    },
    {
        path: SummerCampaignPath,
        name: "Menu.SummerCampaign",
        featureFlag: LotteryCampaign
    }
]

export const mobileMenuHierarchyLv = [
    {
        path: ServicePath,
        name: 'Menu.Service'
    },
    {
        path: InvoicesPath,
        name: 'Menu.Invoices'
    },
    {
        path: ContractsPath,
        name: 'Menu.Contracts'
    },
    {
        path: alexelaUrlLv,
        name: 'TopContent.Navigation.Web'
    }
]

export const mobileMenuHierarchyCompany = [
    {
        path: ServicePath,
        name: 'Menu.Service',
        children: [
            {
                path: InvoicesPath,
                name: 'Menu.Invoices'
            },
            {
                path: PaymentsPath,
                name: 'Menu.Payments'
            },
            {
                path: ContractsPath,
                name: 'Menu.Contracts'
            },
            {
                path: CardsPath,
                name: 'Menu.Cards'
            },
            {
                path: NordpoolPath,
                name: 'Menu.Nordpool'
            },
            {
                path: FuelTaxiOrderPath,
                name: 'Menu.FuelTaxiOrderForm'
            }
        ]
    },
    {
        path: ConsumptionPath,
        name: 'Menu.Consumption'
    },
    {
        path: ProductionPath,
        name: 'Menu.Production'
    },
    {
        path: CommunityPath,
        name: 'Menu.Community'
    },
    {
        path: StatisticsPath,
        name: 'Menu.Statistics',
    },
    {
        path: alexelaUrl,
        name: 'TopContent.Navigation.Web'
    }
]