import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const getActiveTicketCount = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "GET_ACTIVE_TICKET_COUNT",
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/campaign/active-ticket-count`)
    };
}

export const spinWheel = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "SPIN_WHEEL",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/campaign/spin-wheel`)
    };
}

export const clearSpinWheelResult = () => {
    return {
        type: "SPIN_WHEEL_CLEAR",
    };
}