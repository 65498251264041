import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import moment from "moment";

import BaseFormComponent from "../components/common/BaseFormComponent";
import Tabs from "../components/common/Tabs";
import PaymentCardInvoicesContainer from "../components/invoices/PaymentCardInvoicesContainer";
import ElectricityInvoicesContainer from "../components/invoices/ElectricityInvoicesContainer";
import {IsLatvianWeb} from "../helpers/AlternativeDesign";
import { Statuses } from "../const/Invoice";
import { fetchContracts } from "../actions/contractActions";
import AlertMessage from "../components/common/AlertMessage";
import { Link } from "react-router-dom";
import { ServicePath } from "../const/routes"

class Invoices extends BaseFormComponent {

    static TabIndex = { Electricity: 0, PaymentCard: 1 };

    timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            tabIndex: Invoices.TabIndex.PaymentCard,
            electricityInvoicesLoaded: false,
            paymentCardInvoicesLoaded: false,
            isLatvianWeb: IsLatvianWeb(),
        };

        this.onElectricityInvoicesLoaded = this.onElectricityInvoicesLoaded.bind(this);
        this.onPaymentCardInvoicesLoaded = this.onPaymentCardInvoicesLoaded.bind(this);
        this.waitForBothInvoicesLoaded = this.waitForBothInvoicesLoaded.bind(this);
        this.getIndexOfTabToShow = this.getIndexOfTabToShow.bind(this);
    }

    componentDidMount() {
        if (!this.props.contractsFetching && !this.props.contractsFetched) {
            this.props.fetchContracts();
        }

        this.waitForBothInvoicesLoaded();
    }

    waitForBothInvoicesLoaded() {
        clearTimeout(this.timeout);
        if (this.state.electricityInvoicesLoaded && this.state.paymentCardInvoicesLoaded) {
            const tabIndex = this.getIndexOfTabToShow();
            this.setState({ tabIndex: tabIndex });
        } else {
            this.timeout = setTimeout(this.waitForBothInvoicesLoaded, 100); /* this checks the flag every 100 milliseconds*/
        }
    }

    onElectricityInvoicesLoaded() {
        this.setState({ electricityInvoicesLoaded: true });
    }

    onPaymentCardInvoicesLoaded() {
        this.setState({ paymentCardInvoicesLoaded: true });
    }

    sortByInvoiceDate(a, b) { return moment(a.invoiceDate).isBefore(b.invoiceDate); }

    get unpaidElectricityInvoices() {
        const { electricityInvoices } = this.props;

        return electricityInvoices && electricityInvoices.length > 0
            ? electricityInvoices
                .filter(x => x.status === Statuses.StatusUnpaid)
                .sort(this.sortByInvoiceDate)
                .map(x => ({ id: x.id, invoiceDate: x.invoiceDate }))
            : [];
    }

    get unpaidPaymentCardInvoices() {
        const { paymentCardInvoices } = this.props;

        return paymentCardInvoices && paymentCardInvoices.length > 0
            ? paymentCardInvoices
                .filter(x => x.status === Statuses.StatusUnpaid)
                .sort(this.sortByInvoiceDate)
                .map(x => ({ id: x.id, invoiceDate: x.invoiceDate }))
            : [];
    }

    getIndexOfTabToShow() {
        const { paymentCardInvoices, electricityInvoices } = this.props;

        const unpaidElectricityInvoices = this.unpaidElectricityInvoices;
        const unpaidPaymentCardInvoices = this.unpaidPaymentCardInvoices;

        const anyUnpaidElectricity = unpaidElectricityInvoices.length > 0;
        const anyUnpaidPaymentCard = unpaidPaymentCardInvoices.length > 0;
        const anyElectricity = electricityInvoices && electricityInvoices.length > 0;
        const anyPaymentCard = paymentCardInvoices && paymentCardInvoices.length > 0;

        if (!anyUnpaidElectricity && !anyUnpaidPaymentCard) { // no unpaid invoices
            // has electricity but not paymentcard
            if (anyElectricity && !anyPaymentCard) { return Invoices.TabIndex.Electricity; }
            // has paymentcard but not electricity
            else if (!anyElectricity && anyPaymentCard) { return Invoices.TabIndex.PaymentCard; }
            // has both or neither
            else { return this.state.initialTabIndex; }
        }
        else if (anyUnpaidElectricity && !anyUnpaidPaymentCard) { return Invoices.TabIndex.Electricity; }
        else if (!anyUnpaidElectricity && anyUnpaidPaymentCard) { return Invoices.TabIndex.PaymentCard; }
        else { // both have unpaid invoices
            return moment(unpaidElectricityInvoices[0].invoiceDate).isAfter(unpaidPaymentCardInvoices[0].invoiceDate)
                ? Invoices.TabIndex.Electricity // electricity invoice is newer
                : Invoices.TabIndex.PaymentCard; // payment card invoice is newer
        }
    }

    render() {
        const hasFuelContracts = this.props.contracts.find(c => c.type === "Fuel") !== undefined;
        const defaultTabIndex = hasFuelContracts && !this.state.isLatvianWeb ? this.state.tabIndex : Invoices.TabIndex.Electricity;
        const tabItems = [{
            label: <FormattedMessage id={this.state.isLatvianWeb ? "Invoices.InvoiceType.Gas" : "Invoices.InvoiceType.ElectricityAndGas"} />,
            contents: <ElectricityInvoicesContainer didFinishInitialLoad={this.onElectricityInvoicesLoaded} />,
            icon: this.unpaidElectricityInvoices.length > 0 && { icon: "alert-small", className: "mdc-theme--alert" }
        }];

        if (!this.state.isLatvianWeb){
            if (hasFuelContracts) {
                tabItems.push({
                    label: <FormattedMessage id="Invoices.InvoiceType.PaymentCard" />,
                    contents: <PaymentCardInvoicesContainer didFinishInitialLoad={this.onPaymentCardInvoicesLoaded} />,
                    icon: this.unpaidPaymentCardInvoices.length > 0 && { icon: "alert-small", className: "mdc-theme--alert" }
                });
            } else {
                tabItems.push({
                    label: <FormattedMessage id="Invoices.InvoiceType.PaymentCard" />,
                    contents:
                        <AlertMessage
                            className="mt-40"
                            type={AlertMessage.TYPE_NOTICE}
                            title={
                                <FormattedMessage
                                    id="Invoices.PaymentCard.NoContract"
                                    values={{
                                        link:
                                            <Link to={ServicePath}>
                                                <FormattedMessage id="Invoices.PaymentCard.NoContract.LinkText" />
                                            </Link>
                                    }}
                                />
                            }
                        />
                });
            }
        }

        return (
            <Grid className="mdc-layout-grid--base mb-60">
                <GridCell span={12}>
                    <Tabs
                        items={tabItems}
                        defaultTabIndex={defaultTabIndex}
                    />
                </GridCell>
            </Grid>
        );
    }

}

function mapStateToProps(state) {
    return {
        paymentCardInvoicesFetched: state.paymentCardInvoices.fetched,
        paymentCardInvoicesFetching: state.paymentCardInvoices.fetching,
        paymentCardInvoices: state.paymentCardInvoices.data,
        electricityInvoicesFetched: state.electricityInvoices.fetched,
        electricityInvoicesFetching: state.electricityInvoices.fetching,
        electricityInvoices: state.electricityInvoices.data,
        contracts: state.contracts.data,
        contractsFetching: state.contracts.fetching,
        contractsFetched: state.contracts.fetched
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchContracts
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(Invoices)
);
