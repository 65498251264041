import { useIntl } from "react-intl";
import {SummerCampaignPath} from "../../const/routes";

const CampaignBanner = () => {
    const intl = useIntl();

    return (
        <a
            href={SummerCampaignPath}
            rel="noreferrer"
            className="hero--banner-parent"
        >
            <div className={`hero-banner hero-banner-campaign-image--${intl.locale}`}
            />
        </a>
    );
}

export default CampaignBanner;